import { StyleSheet, ScrollView } from "react-native";
import React, { useEffect } from "react";
import { View, Text } from "../components/Themed";
import Box from "../components/Box";

import {
  RootStackScreenProps,
  RootStackParamList,
  NotificationObj,
} from "../types";
import { supabase } from "../lib/superbase";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { ListItem } from "../components/ListItem";
import { convertToArray, convertToList } from "../utils/convertDataStructures";
import {
  removeNotification,
  setNotifications,
} from "../reducers/notificationsReducer";
import dayjs from "dayjs";
import { useTheme } from "react-native-paper";
import { Notification } from "iconsax-react-native";

type NotificationsProps = NativeStackScreenProps<
  RootStackParamList,
  "Notifications"
>;
const Notifications = ({ navigation }: NotificationsProps) => {
  const Dispatch = useAppDispatch();
  const { colors } = useTheme();

  const { messages } = useAppSelector((state) => state.notification);
  const msgs = convertToArray(messages);

  useEffect(() => {
    async function getNotifications() {
      const { data } = await supabase
        .from("notifications")
        .select()
        .order("id", { ascending: true })
        .limit(50);
      const d = data?.map((i) => {
        return {
          ...i,
          read: false,
        };
      }) as NotificationObj[];

      if (data && data?.length > 0) {
        // console.log({ data });
        Dispatch(setNotifications(convertToList(d, "id")));
      }
    }

    // getNotifications();
  }, []);

  const markAsRead = (m: NotificationObj) => {
    Dispatch(removeNotification(m.id));

    navigation.navigate("ViewProject", {
      id: m.id,
    });
  };

  return msgs?.length > 0 ? (
    <View style={styles.container}>
      {/* <Header title="Security" onPressBack={() => navigate("/settings")} /> */}
      <ScrollView style={styles.scrollView}>
        {msgs.map((m, i) => (
          <ListItem
            key={i}
            icon="bell"
            iconColor="grey"
            titleStyles={m?.read ? { color: "grey" } : { fontWeight: "bold" }}
            title={m.title}
            description={m.body}
            description2={
              <Text fontSize={12}>{dayjs(m.date).format("DD MMM")}</Text>
            }
            callback={() => markAsRead(m)}
          />
        ))}
      </ScrollView>
    </View>
  ) : (
    <Box
      flex={1}
      justifyContent="center"
      alignItems="center"
      // backgroundColor={"green"}
    >
      <Box
        borderRadius={100}
        height={100}
        width={100}
        backgroundColor={colors?.surface}
        justifyContent="center"
        alignItems="center"
      >
        <Notification color={"#999"} size={40} />
      </Box>
      <Text marginTop={5} color="#ccc">
        All caught up!
      </Text>
    </Box>
  );
};

export default Notifications;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    padding: 15,
  },
});
