import { ScrollView, StyleSheet } from "react-native";
import { RootTabScreenProps } from "../types";
import { View } from "../components/Themed";

import Header from "../components/Header";
import { ListItem } from "../components/ListItem";
import Avatar from "../components/Avatar";

import { activeStatesData } from "../Constants";

type ChatListProps = RootTabScreenProps<"ChatTab">;
const ChatList = ({ navigation }: ChatListProps) => {
  const openGroupChat = (state: string) => {
    navigation.navigate("GroupChat", {
      state,
    });
  };

  return (
    <View display="flex">
      <Header
        title="Chats"
        onPressBack={() => {}}
        showBack={false}
        showSearch={false}
        backgroundColor="#04203d"
      />

      <ScrollView contentContainerStyle={styles.scrollView}>
        {activeStatesData.map((state) => (
          <ListItem
            key={state.name}
            title={`${state?.name} Group`}
            description={"Group chat"}
            left={<Avatar source={state?.image} />}
            boxStyles={{ borderBottomColor: "#555" }}
            callback={() => openGroupChat(state?.name)}
          />
        ))}
      </ScrollView>
    </View>
  );
};

export default ChatList;

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: 15,
    // flex: 1,
    paddingBottom: 20,
  },
});
