import {
  StyleSheet,
  Dimensions,
  Image,
  ActivityIndicator,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";

import { Text, View } from "../components/Themed";
import {Portal, Snackbar, TextInput} from "react-native-paper";
import { useState } from "react";
import { useAppDispatch } from "../hooks/redux";
import { supabase } from "../lib/superbase";
import { Button } from "react-native-paper";
import Header from "../components/Header";

const RTLALogo = require("../assets/rtla-logo.png");

const Login = ({ navigation }: any) => {
  const Dispatch = useAppDispatch();
  const { height } = Dimensions.get("screen");
  // const { Toast } = Incubator;

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [visible, setVisible] = useState<string | null>()

  const send = async () => {
    if (loading || !email) return;
    setLoading(true);

    const { data, error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      // setToastData({
      //   show: true,
      //   message: "Invalid email",
      // });
      setVisible("Invalid email")
      setLoading(false);
    } else {
      setSent(true);
      // setToastData({
      //   message: "Password reset email sent",
      //   show: true,
      // });
      setVisible("Password reset email sent")

      setLoading(false);
    }
  };

  

  return (
    <ScrollView contentContainerStyle={styles.scrollView}>
      <Header
        title=""
        backgroundColor="transparent"
        onPressBack={() => navigation.goBack()}
        style={{ position: "absolute", top: 10 }}
      />

      <View style={{ justifyContent: "center" }} paddingH-35>
        <Text textAlign="center" fontSize={18} color="#eee" marginBottom={20}>
          Forgot your password?
        </Text>
        <Text textAlign="center" color="#eee" marginBottom={20}>
          We will send you a link that you can use to reset your password.
        </Text>

        {!sent ? (
          <KeyboardAvoidingView>
            <View>
              <Text marginTop={20} marginBottom={5} color="#ddd">
                Email
              </Text>
              <TextInput
                placeholder="Your email"
                placeholderTextColor={"#aaa"}
                // containerStyle={styles.inputContainerStyle}
                onChangeText={(text) => setEmail(text)}
                keyboardType="email-address"
                autoCapitalize="none"
                // color={"#ddd"}
                onSubmitEditing={send}
              />

              <Button
                mode="contained"
                onPress={send}
                loading={loading}
                // disabled={loading}
                style={styles.signinBtn}
              >
                Submit
              </Button>
            </View>
          </KeyboardAvoidingView>
        ) : (
          <View>
            <Text color="green" textAlign="center" marginBottom={20} marginTop={10}>
              Check your inbox
            </Text>
            <Button onPress={() => setSent(false)}>Resend</Button>
          </View>
        )}
      </View>

      {/* <Toast
        message={toastData.message}
        visible={toastData.show}
        position={"bottom"}
        autoDismiss={5000}
        onDismiss={onDismissToast}
        // backgroundColor={Colors.red40}
      /> */}

      <View
        style={{
          // backgroundColor: "orange",
          height: 100,
          width: 100,
          position: "absolute",
          bottom: 0,
          alignSelf: "center",
        }}
      >
        <Image
          source={RTLALogo}
          resizeMode="contain"
          style={{ width: "100%" }}
        />
      </View>

      <Portal>
          <Snackbar
            duration={2000}
            visible={!!visible}
            onDismiss={() => setVisible(null)}
          >
            {visible}
          </Snackbar>
        </Portal>
    </ScrollView>
    // </LinearGradient>
  );
};

export default Login;

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: "#063360",
    flex: 1,
    justifyContent: "center",
  },
  logoStyle: { width: 200, height: 60, alignSelf: "center" },
  inputContainerStyle: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  signinBtn: {
    marginTop: 20,
  },
});
