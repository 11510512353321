import {
  ColorValue,
  StyleProp,
  StyleSheet,
  ViewProps,
  ViewStyle,
} from "react-native";
import { View } from "./Themed";
import React from "react";

type p = keyof ViewStyle;

interface RowProps extends ViewProps, ViewStyle {
  children?: React.ReactElement | React.ReactElement[] | undefined;
  // backgroundColor: ColorValue | undefined,
  // color: ColorValue | undefined,

  // flexDirection: 'row' | 'column',
  // style?: StyleProp<ViewStyle> | undefined;
}
const Row = ({ children, style, ...rest }: RowProps) => {
  return (
    <View {...rest} style={[style, { ...rest }, { flexDirection: "row" }]}>
      {children}
    </View>
  );
};

export default Row;

const styles = StyleSheet.create({});
