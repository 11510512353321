import {
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";

import { Text, View } from "../components/Themed";
import Box from "../components/Box";
import Input from "../components/Input";
import { Picker } from "@react-native-picker/picker";
import { Button, Portal, Snackbar } from "react-native-paper";

import { Complaint, RootStackParamList } from "../types";
import useAppTheme from "../hooks/theme";
import { supabase } from "../lib/superbase";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

const ContactSupport = ({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Support">) => {
  // const { Toast } = Incubator;
  const ThemeColors = useAppTheme().colors;

  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [toastData, setToastData] = useState({ message: "", show: false });
  const [visible, setVisible] = useState<string | null>();
  let timeout: any;

  const submit = async () => {
    if (!category || !subject || !description) return;

    try {
      setIsLoading(true);
      const d: Partial<Complaint> = {
        category,
        subject,
        description,
        createdAt: new Date().toUTCString(),
      };

      const { error, data } = await supabase.from("support").insert(d);

      if (error) {
        setIsLoading(false);
        // something went wrong
        // console.log(error);
        // setToastData({
        //   show: true,
        //   message: "Something went wrong. Please try again",
        // });
        setVisible("Something went wrong. Please try again");
      } else {
        setIsLoading(false);
        // Request submitted successfully

        // setDescription("");
        // setCategory("");
        // setSubject("");
        // setToastData({ show: true, message: "Message sent successfully" });
        setVisible("Message sent successfully");

        timeout = setTimeout(() => {
          navigation.goBack();
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      // Something went wrong. Please try again
    }
  };

  function onDismissToast() {
    // setToastData({
    //   message: "",
    //   show: false,
    // });
    setVisible("");
  }

  useEffect(() => {
    return clearTimeout(timeout);
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Text>Get help from our support team by completing this form</Text>
        <KeyboardAvoidingView style={{ flex: 1 }}>
          <Box marginTop={15}>
            <Text style={styles.label}>Select category</Text>
            <Box
              borderColor={ThemeColors?.inputBorder}
              borderWidth={1}
              borderRadius={4}
              padding={4}
            >
              <Picker
                selectedValue={category}
                onValueChange={(itemValue, itemIndex) => setCategory(itemValue)}
                // selectionColor={ThemeColors?.inputBorder}
                dropdownIconColor={ThemeColors?.inputBorder}
                style={{
                  borderRadius: Platform?.OS === "web" ? 0 : 20,
                  borderWidth: Platform?.OS === "web" ? 0 : 1,
                  color: "#fff",
                  backgroundColor: "transparent",
                  padding: Platform?.OS === "web" ? 10 : 0,
                }}
              >
                <Picker.Item label="Select" value="" />
                <Picker.Item label="Technical issues" value="technical" />
                <Picker.Item label="UI/UX issues" value="ui" />
              </Picker>
            </Box>
          </Box>

          <Box marginTop={13}>
            <Text style={styles.label}>Subject</Text>
            <Input
              placeholder="Subject"
              variant="outlined"
              onChangeText={setSubject}
            />

            <Box marginTop={15}>
              <Text style={styles.label}>Description</Text>
              <Input
                placeholder="Description"
                variant="outlined"
                onChangeText={setDescription}
                multiline={true}
              />
            </Box>
          </Box>

          <Button
            style={styles.submit}
            // size="lg"
            onPress={submit}
            // isDisabled={isLoading}
            loading={isLoading}
            mode="contained"
            textColor="#fff"
            // rounded="full"
          >
            Submit
          </Button>
        </KeyboardAvoidingView>

        {/* <Toast
          message={toastData.message}
          visible={toastData.show}
          position={"bottom"}
          autoDismiss={5000}
          onDismiss={onDismissToast}
          // backgroundColor={Colors.red40}
        /> */}

        <Portal>
          <Snackbar
            duration={2000}
            visible={!!visible}
            onDismiss={() => setVisible(null)}
          >
            {visible}
          </Snackbar>
        </Portal>
      </ScrollView>
    </View>
  );
};

export default ContactSupport;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    padding: 15,
    flex: 1,
  },
  label: { marginBottom: 5 },
  submit: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    color: "#fff",
    // marginTop: 20,
  },
});
