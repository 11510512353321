import {
  ScrollView,
  StyleSheet,
  Dimensions,
  Alert,
  ImageBackground,
  TouchableHighlight,
  Modal,
} from "react-native";
import { View } from "../components/Themed";

import React, { useCallback, useEffect, useState } from "react";
import { Report, RootStackParamList } from "../types";
import { useAppSelector, useAppDispatch } from "../hooks/redux";

import { addReport, deleteReport } from "../reducers/reportsReducer";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Text,
  Menu,
  Button,
  Dialog,
  Snackbar,
  Chip,
  Divider,
  useTheme,
} from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { supabase } from "../lib/superbase";
import { Image } from "react-native";
import { TouchableOpacity } from "react-native";
import Box from "../components/Box";
import Header from "../components/Header";
import ImageViewer from "react-native-image-zoom-viewer";
import { useFocusEffect } from "@react-navigation/native";

type ViewProjectsProps = NativeStackScreenProps<
  RootStackParamList,
  "ViewProject"
>;
const ViewProject = ({ navigation, route }: ViewProjectsProps) => {
  const Dispatch = useAppDispatch();
  const theme = useTheme();

  const auth = useAppSelector((state) => state.auth);
  const user = auth.user;

  // const appTheme = useTheme();
  const { width } = Dimensions.get("screen");
  const id = route.params.id;

  const { reports } = useAppSelector((state) => state.report);
  const project = reports[id];
  // const project = reports[id];

  const [visible, setVisible] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  // const [project, setProject] = useState<Report>();
  const [modalVisible, setModalVisible] = useState(false);
  const [index, setIndex] = useState(0);

  const images = project?.pictures?.map((itm) => ({ url: itm }));

  const openProject = () => {
    setVisible(false);
    navigation.navigate("AddProject", {
      report: project,
    });
  };
  async function getReport() {
    const { data } = await supabase.from("reports").select().eq("id", id);
    if (data && data.length > 0) {
      // setProject(data?.[0]);

      Dispatch(addReport(data?.[0]));
    } else {
      // Alert.alert("An error occured");
    }
  }

  useFocusEffect(
    useCallback(() => {
      if (reports[id]) {
        // setProject(reports[id]);
      } else {
        getReport();
      }
    }, [])
  );

  const deleteTheReport = async () => {
    if (user?.role === "admin" || user?.uid === project?.uid) {
      setShowDialog(false);

      await supabase.from("reports").delete().eq("id", project?.id);
      Dispatch(deleteReport(project!));

      setShowSnackbar(true);

      setTimeout(() => {
        navigation.goBack();
      }, 3000);
    }
  };

  return (
    <View flex={1}>
      <Header
        title=""
        onPressBack={() => navigation.goBack()}
        backgroundColor={"transparent"}
        right={
          <View>
            {user?.uid === project?.uid && (
              <Menu
                visible={visible}
                onDismiss={() => setVisible(false)}
                anchor={
                  <TouchableOpacity onPress={() => setVisible(true)}>
                    <MaterialCommunityIcons
                      name="dots-vertical"
                      color="#ccc"
                      size={20}
                    />
                  </TouchableOpacity>
                }
              >
                <Menu.Item
                  onPress={openProject}
                  title="Edit"
                  leadingIcon={(color) => (
                    <MaterialCommunityIcons
                      name="pencil"
                      color="#ccc"
                      size={20}
                    />
                  )}
                />

                <Menu.Item
                  onPress={() => {
                    setVisible(false);
                    setShowDialog(true);
                  }}
                  title="Delete"
                  leadingIcon={(color) => (
                    <MaterialCommunityIcons
                      name="delete"
                      size={20}
                      color={"red"}
                    />
                  )}
                />
              </Menu>
            )}
          </View>
        }
        style={{
          position: "absolute",
          width: "100%",
          backgroundColor: "transparent",
          zIndex: 100,
        }}
      />
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ImageBackground
          source={{ uri: project?.pictures?.[0] }}
          style={{
            height: project?.pictures?.length ? 300 : 200,
            width: "100%",
            justifyContent: "flex-end",
            backgroundColor: theme.colors?.surface,
          }}
        >
          <Box
            height={100}
            justifyContent="flex-end"
            backgroundColor={"rgab(0,0,0,0.5)"}
            paddingHorizontal={15}
          >
            <Text variant="labelSmall" style={{ color: theme.colors?.primary }}>
              PROJECT
            </Text>
            <Text
              variant="headlineSmall"
              style={[styles.label, { color: "#fff" }]}
            >
              {project?.projectTitle}
            </Text>
          </Box>
        </ImageBackground>

        <View style={{ padding: 15 }}>
          <Text variant="titleMedium">Contractor</Text>
          <Text variant="labelMedium" style={styles.label}>
            {project?.contractor}
          </Text>
          <Text variant="titleMedium">Supervisor</Text>
          <Text variant="labelMedium" style={styles.label}>
            {project?.supervisor}
          </Text>
          <Text variant="titleMedium">Sector</Text>
          <Text variant="labelMedium" style={styles.label}>
            {project?.sector}
          </Text>
          <Text variant="titleMedium">Location</Text>
          <Text variant="labelMedium" style={styles.label}>
            {project?.lga}, {project?.state}
          </Text>
          <Text variant="titleMedium">Status</Text>
          <Text variant="labelMedium" style={styles.label}>
            {project?.status}
          </Text>
          <Text variant="titleMedium" style={{ marginBottom: 10 }}>
            Images
          </Text>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {/* {project?.pictures?.map((item) => (
              <Image
                source={{ uri: item }}
                style={[styles.image, { width: 0.45 * width }]}
                key={item}
              />
              // <Text>{item}</Text>
            ))} */}

            {images?.map((img, i) => (
              <TouchableHighlight
                key={img.url}
                onPress={() => {
                  setModalVisible(true);
                  setIndex(i);
                }}
                style={styles.img}
              >
                <Image source={{ uri: img.url }} style={styles.img} />
              </TouchableHighlight>
            ))}
          </View>
        </View>
      </ScrollView>

      <Modal visible={modalVisible} transparent={true}>
        <ImageViewer
          imageUrls={images}
          index={index}
          enableImageZoom
          enablePreload={true}
          enableSwipeDown={true}
          onSwipeDown={() => setModalVisible(false)}
          swipeDownThreshold={100}
        />
      </Modal>

      <Snackbar visible={showSnackbar} onDismiss={() => setShowSnackbar(false)}>
        Report deleted successfully
      </Snackbar>

      <Dialog visible={showDialog} onDismiss={() => setShowDialog(false)}>
        <Dialog.Title>Delete Report</Dialog.Title>
        <Dialog.Content>
          <Text variant="bodyMedium">
            Are you sure you want to delete this report
          </Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button textColor="#ccc" onPress={() => setShowDialog(false)}>
            Cancel
          </Button>
          <Button onPress={deleteTheReport}>Continue</Button>
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};

export default ViewProject;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    // flex: 1,
  },
  label: {
    marginBottom: 10,
    color: "#bbb",
  },
  image: {
    marginBottom: 10,
    height: 200,
    borderRadius: 5,
  },
  img: {
    width: "100%",
    height: 240,
    marginBottom: 2,
  },
});
