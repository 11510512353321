import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import notificationReducer from "./reducers/notificationsReducer";
import reportsReducer from "./reducers/reportsReducer";

import { combineReducers } from "redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import thunk from "redux-thunk";

import { persistReducer, persistStore } from "redux-persist";
import themeReducer from "./reducers/themeReducer";
import chatReducer from "./reducers/chatReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  report: reportsReducer,
  theme: themeReducer,
  chat: chatReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
  devTools: true,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
