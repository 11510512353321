import {
  ScrollView,
  StyleSheet,
  ImageBackground,
  ActivityIndicator,
  Platform,
} from "react-native";
import { View } from "../components/Themed";

import React, { useState, useRef } from "react";
import { RootStackParamList } from "../types";
import ActionSheet, {
  ActionSheetRef,
  useScrollHandlers,
} from "react-native-actions-sheet";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Divider, List, Text, useTheme } from "react-native-paper";
import Box from "../components/Box";
import Header from "../components/Header";
import { activeStatesData } from "../Constants";
import WebView from "react-native-webview";
import { ListItem } from "../components/ListItem";
import { StatesAndLGA } from "../lib/states-and-lga";
// import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ArrowRight2 } from "iconsax-react-native";

type ViewStateProps = NativeStackScreenProps<RootStackParamList, "ViewState">;
const ViewState = ({ navigation, route }: ViewStateProps) => {
  const theme = useTheme();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const actionSheetRef2 = useRef<ActionSheetRef>(null);

  const scrollHandlers = useScrollHandlers<ScrollView>(
    "scrollview-1",
    actionSheetRef
  );

  const stateName = route.params.state;
  const state = activeStatesData.find((s) => s.name === stateName);

  const [loading, setLoading] = useState(true);
  // const [mode, setMode] = useState<string>();

  const openProjects = (lga: string, mode: string) => {
    actionSheetRef?.current?.hide();
    actionSheetRef2?.current?.hide();

    if (mode === "projects") {
      navigation.navigate("ViewProjects", {
        state: state?.name!,
        lga,
      });
    } else {
      navigation.navigate("ViewReports", {
        state: state?.name!,
        lga,
      });
    }
  };

  const lgas = StatesAndLGA?.find((s) => s.state === state?.name)?.lgas;

  const WebViewWithLoading = ({ url }: { url: string }) => {
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
      setLoading(false);
    };
    const handleLoadError = () => {
      setLoading(true);
    };

    return (
      <View style={{ flex: 1, backgroundColor: theme.colors.background }}>
        {Platform.OS === "web"
          ? false
          : loading && (
              <ActivityIndicator
                size="large"
                color="#fff"
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            )}
        {Platform.OS === "web" ? (
          <iframe
            src={url}
            height={450}
            width={"100%"}
            style={{
              backgroundColor: "#032b57",
              borderColor: "transparent",
              padding: 5,
              overflow: "hidden",
            }}
          ></iframe>
        ) : (
          <WebView
            source={{ uri: url }}
            onLoad={handleLoad}
            onError={handleLoadError}
            style={{ flex: loading ? 0 : 1, opacity: loading ? 0 : 1 }}
          />
        )}
      </View>
    );
  };

  return (
    <View flex={1}>
      <Header
        title=""
        onPressBack={() => navigation.goBack()}
        backgroundColor={"transparent"}
        style={{
          position: "absolute",
          width: "100%",
          backgroundColor: "transparent",
          zIndex: 100,
        }}
      />
      <ScrollView
        contentContainerStyle={styles.scrollView}
        showsVerticalScrollIndicator={false}
      >
        <ImageBackground
          source={state?.image}
          style={{
            height: 300,
            width: "100%",
            justifyContent: "flex-end",
            backgroundColor: theme.colors?.surface,
          }}
        >
          <Box
            height={100}
            justifyContent="flex-end"
            backgroundColor={"rgab(0,0,0,0.5)"}
            paddingHorizontal={15}
          >
            <Text
              variant="headlineSmall"
              style={[styles.label, { color: "#fff" }]}
            >
              {state?.name} State
            </Text>
          </Box>
        </ImageBackground>

        <View style={{ padding: 1, paddingVertical: 10 }}>
          <List.Item
            title="View Projects"
            // description="View projects"
            onPress={() => {
              actionSheetRef?.current?.show();
            }}
            right={(props) => (
              // <MaterialCommunityIcons
              //   {...props}
              //   name="chevron-right"
              //   size={18}
              // />
              <ArrowRight2 {...props} size={18} />
            )}
          />
          <Divider />
          <List.Item
            title="View Reports"
            onPress={() => {
              actionSheetRef2?.current?.show();
            }}
            right={(props) => <ArrowRight2 {...props} size={18} />}
          />

          <View height={400} marginTop={20} paddingHorizontal={15}>
            <WebViewWithLoading
              url={`https://www.followthepromises.org/charts/${state?.name}`}
            />
          </View>
          <Text style={{ textAlign: "center", marginTop: 5 }}>
            Projects by sector
          </Text>
        </View>
      </ScrollView>

      <ActionSheet
        ref={actionSheetRef}
        containerStyle={{
          backgroundColor: "#000",
          paddingHorizontal: 10,
          paddingVertical: 10,
          height: 400,
        }}
      >
        <Text
          style={{
            marginBottom: 5,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Select a local government
        </Text>

        <ScrollView {...scrollHandlers}>
          <ListItem
            title={"All"}
            showUnderline={false}
            titleStyles={{ padding: 3 }}
            callback={() => openProjects("", "projects")}
          />

          {lgas?.map((s) => (
            <ListItem
              title={s}
              key={s}
              showUnderline={false}
              titleStyles={{ padding: 3 }}
              callback={() => openProjects(s, "projects")}
            />
          ))}
        </ScrollView>
      </ActionSheet>

      {/* DUPLICATING THIS ACTION SHEET ON PURPOSE TO PREVENT SETING 'MODE' THAT WILL CAUSE RERENDERING */}
      <ActionSheet
        ref={actionSheetRef2}
        containerStyle={{
          backgroundColor: "#000",
          paddingHorizontal: 10,
          paddingVertical: 10,
          height: 400,
        }}
      >
        <Text
          style={{
            marginBottom: 5,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Select a local government
        </Text>

        <ScrollView {...scrollHandlers}>
          {lgas?.map((s) => (
            <ListItem
              title={s}
              key={s}
              showUnderline={false}
              titleStyles={{ padding: 3 }}
              callback={() => openProjects(s, "reports")}
            />
          ))}
        </ScrollView>
      </ActionSheet>
    </View>
  );
};

export default ViewState;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    // flex: 1,
  },
  label: {
    marginBottom: 10,
    color: "#bbb",
  },
  image: {
    marginBottom: 10,
    height: 200,
    borderRadius: 5,
  },
  img: {
    width: "100%",
    height: 240,
    marginBottom: 2,
  },
});
