import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

interface Auth {
  user: User | null;
}

const initialState: Auth = {
  user: null,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logout: (state, action: PayloadAction<null>) => {
      state.user = null;
    },
  },
});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;
