import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  SafeAreaView,
  ActivityIndicator,
  Image,
  ScrollView,
  Platform,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { WebView } from "react-native-webview";
import { useAppSelector } from "../hooks/redux";
import Header from "../components/Header";

import { Audio } from "expo-av";
import { RootTabScreenProps } from "../types";
import { Button, Snackbar, useTheme } from "react-native-paper";
import Box from "../components/Box";
import { Text, View } from "../components/Themed";
import { supabase } from "../lib/superbase";

async function requestCameraAndAudio() {
  // const { status: cameraStatus } = await Camera.requestCameraPermissionsAsync();
  // if (cameraStatus !== "granted") {
  //   console.log("Camera permission not granted");
  //   return;
  // }

  // Request audio permission
  const { status: audioStatus } = await Audio.requestPermissionsAsync();
  if (audioStatus !== "granted") {
    console.log("Audio permission not granted");
    return;
  }
}

type CreateStreamProps = RootTabScreenProps<"LivestreamTab">;
export default function CreateStream({ navigation }: CreateStreamProps) {
  const { user } = useAppSelector((state) => state.auth);
  const theme = useTheme();

  const insets = useSafeAreaInsets();
  const appID = "947452979";
  const userID = user?.id;
  const userName = user?.firstName?.slice(0, 8);
  const roomID = "ftpdefault";
  const photo = user?.photo || "";

  const [loading, setLoading] = useState(false);
  const [streamOn, setStreamOn] = useState(false);
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });
  const [joined, setJoined] = useState(false);

  const hostUrl = `https://www.ftp-audio.followthepromises.org/?userID=${userID}&userName=${userName}&roomID=${roomID}&appID=${appID}&photo=${photo}&role=host`;
  const speakerUrl = `https://www.ftp-audio.followthepromises.org/speaker.html/?userID=${userID}&userName=${userName}&roomID=${roomID}&appID=${appID}&photo=${photo}&role=speaker`;
  const url = user?.role === "admin" ? hostUrl : speakerUrl;
  // console.log({ url, hostUrl });

  const [restart, setRestart] = useState(false);
  const [failed, setFailed] = useState(false);

  const role = user?.role;

  useEffect(() => {
    // get stream status from db;
    async function listenStreamDetails() {
      supabase
        .channel("custom-all-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "stream" },
          (payload: any) => {
            // console.log("Change received!", payload);

            if (payload?.new?.status === "on") {
              setLoading(true);
              setStreamOn(true);
            }
            if (payload?.new?.status === "off") {
              setStreamOn(false);

              setSnackbar({
                message: "Live stream ended by host",
                show: true,
              });
            }
          }
        )
        .subscribe();
    }

    async function getStreamDetails() {
      const { data } = await supabase.from("stream").select();
      if (data?.[0].status === "on") {
        setStreamOn(true);
      } else {
      }
    }

    if (user && user?.uid) {
      listenStreamDetails();

      getStreamDetails();

      requestCameraAndAudio();
      setFailed(false);
    }
  }, [restart]);

  const startBroadcast = async () => {
    const { data, error } = await supabase
      .from("stream")
      .update({
        status: "on",
      })
      .eq("id", 1);
    console.log({ data, error });

    if (error) {
      setSnackbar({
        message: "Failed to start stream. Please try again",
        show: true,
      });
    }
  };

  const endBroadcast = async () => {
    const { data, error } = await supabase
      .from("stream")
      .update({
        status: "off",
      })
      .eq("id", 1);

    // console.log({ data, error });

    if (error) {
      setSnackbar({
        message: "Failed to end stream. Please try again",
        show: true,
      });
    }
  };

  const closeSnackbar = () => {
    setSnackbar({ show: false, message: "" });
  };

  const RenderLoadingView = () => (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <ActivityIndicator color={"#000"} size="large" />
    </View>
  );

  const StartStreamComponent = () => (
    <Box alignItems="center" justifyContent="center" height={"100%"}>
      <Image
        source={require("../assets/audio-wave.png")}
        style={{ width: 100, height: 50, marginBottom: 15 }}
      />
      <Text textAlign="center" color={"#777"}>
        {role === "admin"
          ? "Start a livestream and notify"
          : "live stream not started"}
      </Text>
      <Text textAlign="center" color={"#777"} marginBottom={20}>
        {role === "admin" ? " members to join" : ""}
      </Text>

      {role === "admin" ? (
        <Button mode="outlined" onPress={startBroadcast}>
          Start Live stream
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );

  const JoinStreamComponent = () => (
    <Box alignItems="center" justifyContent="center" height={"100%"}>
      <Image
        source={require("../assets/livestream.png")}
        style={{ width: 100, height: 50, marginBottom: 15 }}
      />

      <Text textAlign="center" color={"#777"} marginBottom={20}>
        Livestream started
      </Text>

      <Button mode="outlined" onPress={() => setJoined(true)}>
        Join Live stream
      </Button>
    </Box>
  );

  const WebViewWithLoading = ({ url }: { url: string }) => {
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
      setLoading(false);
    };
    const handleLoadFailed = () => {
      setFailed(true);
      setLoading(false);
    };

    return (
      <View style={{ flex: 1, backgroundColor: theme.colors.background }}>
        {Platform.OS !== "web" && loading && (
          <ActivityIndicator
            size="large"
            color="#fff"
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          />
        )}

        {Platform.OS !== "web" && failed && (
          <Button onPress={() => setRestart(true)}>Reconnect</Button>
        )}
        {Platform.OS === "web" ? (
          <iframe
            src={url}
            style={{ height: "100%", overflow: "hidden", borderWidth: 0 }}
          ></iframe>
        ) : (
          <WebView
            source={{ uri: url }}
            onLoad={handleLoad}
            onError={handleLoadFailed}
            style={{ flex: loading ? 0 : 1, opacity: loading ? 0 : 1 }}
          />
        )}
      </View>
    );
  };

  return user && user?.uid ? (
    <SafeAreaView
      style={{
        flex: 1,
        paddingBottom: insets.bottom,
        backgroundColor: "#1c1f2e",
      }}
    >
      <Header
        title=""
        onPressBack={() => {}}
        showBack={false}
        showSearch={false}
        backgroundColor={theme.colors?.surface}
        right={
          streamOn && role === "admin" ? (
            <Button onPress={endBroadcast} compact>
              End broadcast
            </Button>
          ) : (
            <></>
          )
        }
      />

      <Snackbar visible={snackbar.show} onDismiss={closeSnackbar}>
        {snackbar.message}
      </Snackbar>

      {/* {loading && <RenderLoadingView />} */}

      {streamOn && joined ? (
        <WebViewWithLoading url={url} />
      ) : streamOn ? (
        <JoinStreamComponent />
      ) : (
        <StartStreamComponent />
      )}
    </SafeAreaView>
  ) : (
    <View flex={1}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Text fontSize={16} marginBottom={15} color="#ccc">
          Login to join the live stream
        </Text>
        <Button
          mode="contained"
          textColor="#fff"
          onPress={() => navigation.navigate("Login")}
        >
          Login
        </Button>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "#222532",
  },
  scrollView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
