import {
  ScrollView,
  StyleSheet,
  Pressable,
  ActivityIndicator,
} from "react-native";
import { View } from "../components/Themed";
import Box from "../components/Box";
import { ListItem } from "../components/ListItem";
import Row from "../components/Row";
import * as ImagePicker from "expo-image-picker";
import ActionSheet, { ActionSheetRef } from "react-native-actions-sheet";
import { decode } from "base64-arraybuffer";

import { RootTabScreenProps, User } from "../types";
import { useAppSelector, useAppDispatch } from "../hooks/redux";
import Column from "../components/Column";
import Header from "../components/Header";
import { setUser } from "../reducers/authReducer";
import {
  Text,
  useTheme,
  Avatar,
  Button,
  List,
  Portal,
  Snackbar,
} from "react-native-paper";
import {
  Edit,
  Layer,
  LogoutCurve,
  MessageQuestion,
  SecurityUser,
} from "iconsax-react-native";
import { useState, useRef } from "react";
import { supabase } from "../lib/superbase";

type SettingsProps = RootTabScreenProps<"SettingsTab">;
const Settings = ({ navigation }: SettingsProps) => {
  const auth = useAppSelector((state) => state.auth);
  const appTheme = useTheme();
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const user = auth.user;

  const [pictures, setPictures] = useState<ImagePicker.ImagePickerAsset[]>();
  const [visible, setVisible] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const Dispatch = useAppDispatch();
  const signout = () => {
    Dispatch(setUser({} as User));
  };

  const pickImageAsync = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      // allowsEditing: true,
      quality: 1,
      base64: true,
      allowsMultipleSelection: false,
      allowsEditing: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled) {
      try {
        setLoading(true);
        const uploaded = await uploadImgs(result.assets);

        const { error } = await supabase
          .from("users")
          .update({
            photo: uploaded[0],
          })
          .eq("uid", user?.uid!);

        if (error) {
          setVisible("Picture upload failed. Please try again");
        } else {
          Dispatch(setUser({ ...user!, photo: uploaded[0] }));
          setVisible("Profile picture updated successfully");
        }

        setLoading(false);
        // setPictures(result.assets);
      } catch (error) {
        setVisible("Picture upload failed. Please try again");
        setLoading(false);
      }
    }
  };

  const takeAPhotoAsync = async () => {
    const permission = await ImagePicker.getCameraPermissionsAsync();
    // console.log({ permission });
    if (permission.granted) {
      select();
    } else {
      const p = await ImagePicker.requestCameraPermissionsAsync();
      if (p.granted) {
        select();
      }
    }

    async function select() {
      // console.log("add");
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [4, 3],
        quality: 1,
      });

      // console.log({ result });

      if (!result.canceled) {
        // console.log(result.assets);
        setPictures(result.assets);
      }
    }
  };

  async function uploadImgs(images: ImagePicker.ImagePickerAsset[]) {
    const imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      const imageName = `${Date.now()}`; //user?.id;

      const { data, error } = await supabase.storage
        .from("uploads")
        .upload(`profiles/${imageName}.jpeg`, decode(image.base64 || ""), {
          upsert: true,
        });

      if (error) {
        // console.log(error);
      } else {
        imageUrls.push(
          "https://gbsdrtsjmdakgsgqfgru.supabase.co/storage/v1/object/public/uploads/" +
            data.path
        );
      }
    }

    return imageUrls;
  }

  return (
    <View flex={1}>
      <Header
        title="Account"
        onPressBack={() => {}}
        showBack={false}
        backgroundColor={appTheme.colors?.surface}
      />
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Box paddingVertical={5} paddingBottom={10} marginTop={10}>
          {user?.id ? (
            <Row
              alignItems="center"
              columnGap={15}
              backgroundColor="transparent"
            >
              <Pressable
                style={{ position: "relative" }}
                onPress={() => actionSheetRef.current?.show()}
              >
                {user?.photo ? (
                  <Avatar.Image source={{ uri: user?.photo }} />
                ) : (
                  <Avatar.Text
                    size={50}
                    label={user?.firstName?.slice(0, 1) || ""}
                  ></Avatar.Text>
                )}
                <View position="absolute" right={10} bottom={-5}>
                  {loading ? (
                    <ActivityIndicator color={"#fff"} size={16} />
                  ) : (
                    <Edit color="#ccc" size={16} />
                  )}
                </View>
              </Pressable>

              <Column backgroundColor="transparent">
                <Text style={{ fontWeight: "bold" }}>
                  {user?.id ? user?.firstName + " " + user?.lastName : "N/A"}
                </Text>
                <Text>
                  {/* @{user?.username} */}
                  {user?.role}
                </Text>
              </Column>
            </Row>
          ) : (
            <Button
              mode="contained"
              textColor="#fff"
              onPress={() => navigation.navigate("Login")}
            >
              Login
            </Button>
          )}
        </Box>

        {user?.id && (
          <ListItem
            title="My Reports"
            description="View all projects you reported"
            left={<Layer color="#ccc" />}
            py={15}
            showRightIcon={true}
            showUnderline={false}
            callback={() => navigation.navigate("MyReports")}
          />
        )}

        {user?.id && (
          <ListItem
            title="Security"
            description="Change password"
            left={<SecurityUser color="#ccc" />}
            py={15}
            showRightIcon={false}
            showUnderline={false}
            callback={() => navigation.navigate("Security")}
          />
        )}
        <ListItem
          title="Help and Support"
          description="Get help"
          left={<MessageQuestion color="#ccc" />}
          py={12}
          showRightIcon={false}
          showUnderline={false}
          callback={() => navigation.navigate("Support")}
        />
        {user?.id && (
          <ListItem
            title="Logout"
            left={<LogoutCurve color="#ccc" />}
            py={12}
            showRightIcon={false}
            showUnderline={false}
            callback={signout}
          />
        )}
      </ScrollView>

      <ActionSheet
        ref={actionSheetRef}
        containerStyle={{ backgroundColor: appTheme.colors?.background }}
      >
        {/* <List.Item
          left={(props) => <List.Icon {...props} icon="camera" />}
          title="Take a photo"
          onPress={() => {
            actionSheetRef?.current?.hide();
            takeAPhotoAsync();
          }}
        ></List.Item> */}
        <List.Item
          left={(props) => <List.Icon {...props} icon="image-multiple" />}
          title="Select from Gallary"
          onPress={() => {
            actionSheetRef?.current?.hide();
            pickImageAsync();
          }}
        ></List.Item>
      </ActionSheet>

      <Portal>
        <Snackbar
          duration={2000}
          visible={!!visible}
          onDismiss={() => setVisible(null)}
        >
          {visible}
        </Snackbar>
      </Portal>
    </View>
  );
};

export default Settings;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    paddingHorizontal: 15,
  },
});
