import { ScrollView, ImageBackground } from "react-native";
import { View, Text } from "../components/Themed";
import Box from "../components/Box";
import { useState } from "react";
import { QuestionsAndAnswers, RootStackParamList } from "../types";
import Input from "../components/Input";

import Button from "../components/Button";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { supabase } from "../lib/superbase";
import { SECTORS_DETAILS } from "../Constants";
import { Portal, SegmentedButtons, Snackbar } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { addQuestion } from "../reducers/reportsReducer";

const CreateReport = ({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "CreateReport">) => {
  const Dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const q = useAppSelector((state) => state.report.questions);

  const sectorId = route.params.sectorId;
  const sector = SECTORS_DETAILS.find((s) => s.id === sectorId);
  const prevSavedResponses = q?.[sector?.name!];

  const questions = sector?.questions || [];
  const prevAnswers = prevSavedResponses?.answers
    ? JSON.parse(prevSavedResponses?.answers)
    : [""];

  const [answers, setAnswers] = useState(prevAnswers || [""]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<string | null>();

  const setAnswer = (val: string, i: number) => {
    const a = [...answers];
    a[i] = val;
    setAnswers(a);
  };

  const submit = async () => {
    if (loading) return;

    setLoading(true);

    if (prevSavedResponses?.id) {
      // update data

      const { data, error } = await supabase
        .from("questions_and_answers")
        .update({
          questions: JSON.stringify(questions),
          answers: JSON.stringify(answers),
          sector: sector?.name,
        })
        .eq("id", prevSavedResponses?.id)
        .select();

      if (error) {
        // console.log(error);
        setLoading(false);
        setVisible("An error occured. Please try again later");
        return;
      }
      const d = data[0] as QuestionsAndAnswers;

      Dispatch(addQuestion(d));

      setLoading(false);
      setVisible("Report submitted successfully");

      setTimeout(() => {
        navigation.goBack();
      }, 2000);
    } else {
      // create new data
      const { data, error } = await supabase
        .from("questions_and_answers")
        .insert({
          questions: JSON.stringify(questions),
          answers: JSON.stringify(answers),
          sector: sector?.name,
          uid: user?.uid,
          state: user?.state,
          lga: user?.lga,
        })
        .select();

      if (error) {
        setLoading(false);
        // console.log(error);
        setVisible("An error occured. Please try again later");
        return;
      }
      const d = data[0] as QuestionsAndAnswers;

      Dispatch(addQuestion(d));

      setLoading(false);
      setVisible("Report submitted successfully");

      setTimeout(() => {
        navigation.goBack();
      }, 2000);
    }
  };

  return (
    <ScrollView>
      <ImageBackground
        source={sector?.img}
        style={{
          height: 100,
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Box
          height={100}
          justifyContent="flex-end"
          backgroundColor={"rgba(0,0,0,0.5)"}
          paddingHorizontal={15}
          paddingBottom={15}
        >
          <Text
            style={{
              color: "#fff",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 22,
            }}
          >
            {sector?.name}
          </Text>
        </Box>
      </ImageBackground>

      <View paddingHorizontal={15} paddingTop={15} paddingBottom={25}>
        <Text marginBottom={10}>{sector?.description}</Text>

        <Text marginBottom={10} fontSize={18} fontWeight={"bold"}>
          Questions
        </Text>

        {questions.map((question, i) => (
          <View marginBottom={25} key={i}>
            <Text fontWeight="bold" marginBottom={15}>
              {question?.text}
            </Text>
            {question?.type === "boolean" ? (
              <SegmentedButtons
                value={answers[i]}
                onValueChange={(val) => setAnswer(val, i)}
                buttons={[
                  {
                    value: "yes",
                    label: "Yes",
                  },
                  {
                    value: "no",
                    label: "No",
                  },
                ]}
              />
            ) : (
              <Input
                multiline
                // numberOfLines={5}
                keyboardType="default"
                defaultValue={answers[i]}
                variant="outlined"
                onChangeText={(v) => setAnswer(v, i)}
              />
            )}
          </View>
        ))}

        <Button
          style={{ marginTop: 30 }}
          rounded
          isLoading={loading}
          onPress={submit}
        >
          Submit
        </Button>

        <Portal>
          <Snackbar visible={!!visible} onDismiss={() => setVisible(null)}>
            {visible}
          </Snackbar>
        </Portal>
      </View>
    </ScrollView>
  );
};

export default CreateReport;
