/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import {
  Text as DefaultText,
  View as DefaultView,
  useColorScheme,
  TextProps,
  ViewProps,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import useAppTheme from "../hooks/theme";

export function useThemeColor() {
  // const theme = useAppSelector((state) => state.theme);
  const colors = useAppTheme().colors;

  return colors;
}

interface ThemeProps {
  lightColor?: string;
  darkColor?: string;
}

interface TProps extends TextProps, ThemeProps, TextStyle {
  style?: StyleProp<TextStyle> | undefined;
  children?: React.ReactNode | undefined;
}
export function Text(props: TProps) {
  const colors = useAppTheme().colors;

  const { style, lightColor, darkColor, children, ...otherProps } = props;
  // const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return (
    <DefaultText
      style={[{ color: colors?.text }, style, { ...otherProps }]}
      {...otherProps}
    >
      {children}
    </DefaultText>
  );
}

interface VProps extends ViewProps, ViewStyle {
  style?: StyleProp<ViewStyle> | undefined;
}

export function View(props: VProps) {
  const { style, ...otherProps } = props;
  const colors = useAppTheme().colors;
  // const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return (
    <DefaultView
      style={[
        // { backgroundColor: colors?.background },
        style,
        { ...otherProps },
      ]}
      {...otherProps}
    />
  );
}
