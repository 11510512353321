import { ScrollView, StyleSheet, View } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../hooks/redux";
import { orderBy } from "lodash";
import { Report, RootStackParamList } from "../types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ListItem } from "../components/ListItem";
import { Document } from "iconsax-react-native";
import { supabase } from "../lib/superbase";
import { ActivityIndicator } from "react-native-paper";
import { useFocusEffect } from "@react-navigation/native";

const MyReports = ({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "MyReports">) => {
  const { user } = useAppSelector((state) => state.auth);

  const [myReports, setMyReports] = useState<Report[] | null>(null);

  const openReport = (item: Report) => {
    navigation.navigate("ViewProject", {
      id: item?.id!,
    });
  };

  async function getReports() {
    const { error, data } = await supabase
      .from("reports")
      .select()
      .eq("state", user?.state)
      .eq("lga", user?.lga)
      .eq("uid", user?.uid);
    const r = data as Report[];

    if (r && r.length > 0) {
      setMyReports(r);
    }

    if (r && r.length == 0) {
      setMyReports([]);
    }
  }

  useFocusEffect(
    useCallback(() => {
      getReports();
    }, [])
  );

  const ListViewItem = ({ item }: { item: Report }) => {
    return (
      <ListItem
        title={item?.projectTitle}
        description={item?.verified ? "Verified" : "Pending"}
        left={<Document color="#ccc" />}
        callback={() => openReport(item)}
      />
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {myReports?.map((r) => (
          <ListViewItem item={r} key={r.id} />
        ))}

        {myReports == null && (
          <View style={styles.loadingView}>
            <ActivityIndicator color="#777" />
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default MyReports;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 60,
  },
  scrollView: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    paddingBottom: 80,
  },
  loadingView: {
    height: 200,
    alignItems: "center",
    justifyContent: "center",
  },
});
