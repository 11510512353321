import {
  FlexStyle,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Text } from "./Themed";
import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Row from "./Row";

import { StatusBar } from "expo-status-bar";
import { IconButton } from "react-native-paper";
import { ArrowLeft } from "iconsax-react-native";

type HeaderProps = {
  onPressBack: () => void;
  title: string;
  showSearch?: boolean;
  showBack?: boolean;
  backgroundColor?: string;
  right?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onPressSearch?: () => void;
  justify?: any;
};
const Header = ({
  onPressBack,
  title,
  showSearch = false,
  showBack = true,
  backgroundColor = "grey",
  right,
  style = {},
  justify,
  onPressSearch,
}: HeaderProps) => {
  return (
    <>
      <Row
        marginTop={
          Platform.OS == "android" ? 30 : Platform?.OS === "ios" ? 20 : 0
        }
        paddingRight={15}
        // backgroundColor={backgroundColor}
        alignItems="center"
        justifyContent={justify || "space-between"}
        // elevation={3}
        height={60}
        style={style}
      >
        <TouchableOpacity
          style={{
            paddingLeft: 20,
            paddingRight: 5,
            paddingVertical: 5,
            flexDirection: "row",
            columnGap: 20,
            alignItems: "center",
          }}
          onPress={onPressBack}
        >
          {showBack ? (
            // <MaterialCommunityIcons name="arrow-left" size={22} color="#fff" />
            <ArrowLeft size={22} color="#fff" />
          ) : (
            <></>
          )}
          <Text fontSize={18} fontWeight="700" color="#fff">
            {title}
          </Text>
        </TouchableOpacity>

        <>
          {showSearch && (
            <IconButton
              icon={"magnify"}
              size={22}
              iconColor="#fff"
              onPress={onPressSearch}
            />
            // <MaterialCommunityIcons name="magnify" size={22} color="#fff" />
          )}

          {right && right}
        </>
      </Row>

      <StatusBar backgroundColor={backgroundColor} style="light" />
    </>
  );
};

export default Header;

const styles = StyleSheet.create({});
