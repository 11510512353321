import React, { useState } from "react";
import { StyleSheet, Pressable, ImageBackground, TouchableOpacity, Platform } from "react-native";
import {
  GiftedChat,
  IChatMessage,
  Send,
  User as MessageUser,
} from "react-native-gifted-chat";
import { supabase } from "../lib/superbase";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import * as ImagePicker from "expo-image-picker";
import { decode } from "base64-arraybuffer";
import { Text, View } from "../components/Themed";
import { Avatar } from "react-native-paper";
import { useActionSheet } from "@expo/react-native-action-sheet";

import { orderBy } from "lodash";
import { convertToArray, convertToList } from "../utils/convertDataStructures";
import { addGroupChatMessage, setGroupChat } from "../reducers/chatReducer";
import { ChatUser, IMessage, RootStackParamList } from "../types";
import { generateUUID } from "../utils/helpers";
import { Json } from "../lib/database.types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  ArrowLeft2,
  Camera,
  FolderAdd,
  Gallery,
  Send2,
} from "iconsax-react-native";
import { activeStatesData } from "../Constants";
import { StatusBar } from "expo-status-bar";

type GroupChatProps = NativeStackScreenProps<RootStackParamList, "GroupChat">;
function GroupChat({ navigation, route }: GroupChatProps) {
  const Dispatch = useAppDispatch();
  const { showActionSheetWithOptions } = useActionSheet();

  const groupId = route.params.state;
  const state = activeStatesData.find((s) => s.name === groupId);

  const {
    auth: { user },
    chat: { groupChatData },
  } = useAppSelector((state) => state);
  const converted = orderBy(convertToArray(groupChatData), "createdAt", "desc");

  const filterd = converted.filter((c) => c.chatId === groupId);

  const groupChats = filterd.map((d) => {
    return {
      ...d,
      user: d.user as unknown as ChatUser,
      createdAt: d.createdAt || new Date(d.created_at || ""),
    } as IChatMessage;
  });

  React.useEffect(() => {
    async function getMessages() {
      const { error, data } = await supabase.from("groupChat").select();

      if (error) return;

      if (data?.length) {
        const chats = data.map((d) => {
          return {
            ...d,
            sent: true,
            user: d.user as unknown as ChatUser,
          };
        });
        Dispatch(setGroupChat(convertToList(chats, "_id")));
      }
    }

    getMessages();

    supabase
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "groupChat",
        },
        (payload) => {
          const m = payload.new;

          Dispatch(
            addGroupChatMessage({
              _id: m._id,
              createdAt: m.createdAt,
              text: m.text,
              image: m.image || "",
              video: undefined,
              chatId: m.chatId,
              audio: undefined,
              system: false,
              sent: true,
              received: false,
              pending: false,
              quickReplies: undefined,
              user: m.user,
            })
          );
        }
      )
      .subscribe();

    // .from("groupChat")
    // .on("*", (payload) => {
    //   if (payload) {
    //     Dispatch(addGroupChatMessage(payload.new as unknown as IChatMessage));
    //   }
    // })
    // .subscribe();

    // return supabase.removeSubscription(subscription);
    // return () => subscription.unsubscribe();
  }, []);

  const headerTitleWithAvatar = () => (
    <View style={styles.header}>
      <TouchableOpacity
        onPress={() => navigation.goBack()}
        style={styles.headerInner}
      >
        <ArrowLeft2 size={20} color="white" style={styles.arrowBack} />

        <Avatar.Image
         
          // labelColor="#ffffff"
          // name={state?.name}
          source={state?.image ?? { uri: state?.image || undefined }}
          size={30}
        />
      </TouchableOpacity>
      <Text style={styles.headerTitle}>{groupId} Group Chat</Text>
    </View>
  );

  async function addToDb(message: IMessage) {
    try {
      const m: IMessage = {
        ...message,
        createdAt: new Date().getTime(),
      };

      Dispatch(
        addGroupChatMessage({
          _id: m._id,
          createdAt: m.createdAt,
          text: m.text,
          image: m.image || "",
          video: undefined,
          chatId: groupId,
          audio: undefined,
          system: false,
          sent: false,
          received: false,
          pending: false,
          quickReplies: undefined,
          user: m.user,
        })
      );

      const { error, data } = await supabase.from("groupChat").insert({
        text: m.text,
        image: m.image || "",
        video: undefined,
        chatId: groupId,
        audio: undefined,
        system: false,
        sent: true,
        received: false,
        pending: false,
        quickReplies: undefined,
        user: message.user as unknown as Json,
        _id: m._id,
        createdAt: m.createdAt,
      });

      // return data;
    } catch (error) {
      // console.log(error);
    }
  }

  const onSend = React.useCallback(
    (messages: IMessage[] = [] as IMessage[]) => {
      addToDb(messages[0]);
    },
    []
  );

  const onPressFile = () => {
    const options = ["Select from Gallery", "Take a photo", "Cancel"];
    const icons = [<Gallery color="#000" />, <Camera color="#333" />];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        icons,
      },
      (selectedIndex: number | undefined) => {
        switch (selectedIndex) {
          case 0:
            // Save
            pickImage();
            break;

          case 1:
            takeAPhoto();
            break;

          case cancelButtonIndex:
          // Canceled
        }
      }
    );
  };

  async function uploadImg(base64: string, id: string) {
    const { error } = await supabase.storage
      .from("uploads")
      .upload(`group_chats/${id}.jpg`, decode(base64 || ""));

    if (error) {
      // console.log(error);
      return null;
    } else {
      const d = supabase.storage
        .from("uploads")
        .getPublicUrl(`group_chats/${id}.jpg`);

      return d.data.publicUrl;
    }
  }

  const takeAPhoto = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      // aspect: [3, 5],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      addImageToChat(result);
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      // aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      addImageToChat(result);
    }
  };

  const addImageToChat = async (
    result: ImagePicker.ImagePickerSuccessResult
  ) => {
    const id = generateUUID();
    const m: IMessage = {
      chatId: groupId,
      _id: id,
      createdAt: new Date().getTime(),
      image: result.assets[0].uri,
      text: "",
      sent: false,
      pending: true,
      user: {
        _id: user!.uid,
        name: user?.firstName + " " + user?.lastName,
        avatar: user?.photo || "",
      },
      quickReplies: undefined,
      received: false,
      system: false,
    };

    Dispatch(addGroupChatMessage(m));

    const uploaded =
      (await uploadImg(result.assets[0].base64!, id)) || undefined;

    // ToDO: if upload failed, show alert instead

    const d = {
      ...m,
      image: uploaded,
      sent: true,
      pending: false,
    };
    const { error, data } = await supabase.from("groupChat").insert({
      ...d,
      createdAt: new Date().getTime(),
      user: m.user as unknown as Json,
    });

    if (error) {
      d.sent = false;
    }

    d.image = result.assets?.[0]?.uri;
    Dispatch(addGroupChatMessage(d));
  };

  return (
    <>
      <StatusBar backgroundColor="#06223e" />
      {headerTitleWithAvatar()}
      <ImageBackground
        source={require("../assets/chat-bgd-2.jpeg")}
        style={{ flex: 1 }}
      >
        {Platform.OS === 'web' ? <></> : <GiftedChat
          renderUsernameOnMessage={true}
          {...{ messages: groupChats, onSend }}
          user={{
            _id: user!.uid,
            name: user?.firstName + " " + user?.lastName,
            avatar: user?.photo || undefined,
          }}
          renderSend={(props: any) => (
            <Send {...props}>
              <Send2
                color="#000"
                size={25}
                style={{ marginRight: 18, marginBottom: 10 }}
              />
            </Send>
          )}
          renderActions={() => {
            return (
              <Pressable
                onPress={onPressFile}
                style={{
                  width: 50,
                  height: 50,
                  // backgroundColor: "#eee",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FolderAdd
                  size={25}
                  color="#000"
                  // style={{ marginBottom: 8, marginLeft: 15 }}
                />
              </Pressable>
            );
          }}
        />}
      </ImageBackground>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 45,
    paddingBottom: 10,
    backgroundColor: "#04203d",
  },
  headerInner: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerTitle: {
    marginLeft: 15,
    color: "#fff",
    fontSize: 18,
    textTransform: "capitalize",
  },
  arrowBack: {
    marginLeft: 3,
    marginRight: 5,
  },
});
export default GroupChat;
