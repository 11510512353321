import {
  StyleSheet,
  Image,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";

import { Text, View } from "../components/Themed";
import { useState } from "react";
import { setUser } from "../reducers/authReducer";
import { useAppDispatch } from "../hooks/redux";
import { supabase } from "../lib/superbase";
import { Role, Roles, RootStackParamList } from "../types";
import {TextInput, Button, Portal, Snackbar } from "react-native-paper";
import Header from "../components/Header";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

const Logo = require("../assets/ftp-logo-white.png");
const RTLALogo = require("../assets/rtla-logo.png");

const Login = ({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Login">) => {
  const Dispatch = useAppDispatch();
  // const { height } = Dimensions.get("screen");
  // const { Toast } = Incubator;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [toastData, setToastData] = useState({ message: "", show: false });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<string | null>();

  async function fetchUserData(uid: string) {
    if (loading) return;

    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("uid", uid);

    if (error || !data?.[0]) {
      // console.log({ error, data });
      // setToastData({
      //   message: "Something went wrong, please try again later",
      //   show: true,
      // });
      setVisible("Something went wrong, please try again later")
      setLoading(false);
      return;
    }

    const { firstName, lastName, email, photo, id, lga, state, role } = data[0];

    // navigate("/");

    Dispatch(
      setUser({
        firstName,
        lastName,
        email,
        id,
        uid,
        photo,
        lga: lga || "",
        state: state || "",
        role: (role as Role) || Roles.profo,
      })
    );

    navigation.goBack();
  }

  const login = async () => {
    setLoading(true);

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      // setToastData({
      //   message: error.message,
      //   show: true,
      // });
      setVisible(error.message)

      setLoading(false);
      return;
    } else if (data.user) {
      fetchUserData(data.user.id);
    }
  };

  function onDismissToast() {
    // setToastData({
    //   message: "",
    //   show: false,
    // });
    setVisible('')
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollView}>
      <Header
        title=""
        backgroundColor="transparent"
        onPressBack={() => navigation.goBack()}
        style={{ position: "absolute", top: 10 }}
      />
      <View style={{ justifyContent: "center" }} paddingHorizontal={35}>
        <Image source={Logo} style={styles.logoStyle} />
        <Text textAlign="center" color="#eee" fontSize={18} marginTop={10} marginBottom={20}>
          Sign In
        </Text>

        <KeyboardAvoidingView>
          <View>
            <Text marginTop={20} marginBottom={5} color="#ddd">
              Email
            </Text>
            <TextInput
              placeholder="Your email"
              placeholderTextColor={"#aaa"}
              mode="outlined"
              // containerStyle={styles.inputContainerStyle}
              onChangeText={(text) => setEmail(text)}
              keyboardType="email-address"
              autoCapitalize="none"
              // color={"#ddd"}
            />

            <Text marginTop={20} marginBottom={5} color="#ddd">
              Password
            </Text>

            <TextInput
              placeholder="Your password"
              placeholderTextColor={"#aaa"}
              secureTextEntry
              mode='outlined'
              // containerStyle={styles.inputContainerStyle}
              onChangeText={(text) => setPassword(text)}
              // color={"#ddd"}
            />

            <Button
              mode="contained"
              onPress={login}
              loading={loading}
              labelStyle={{ color: "#fff" }}
              style={styles.signinBtn}
            >
              Sign In
            </Button>

            {/* <Button
              mode="text"
              labelStyle={{ textDecorationLine: "underline", color: "#fff" }}
              onPress={() => navigation.navigate("ForgotPassword")}
            >
              Forgot password?
            </Button> */}
          </View>
        </KeyboardAvoidingView>
      </View>

      {/* <Toast
        message={toastData.message}
        visible={toastData.show}
        position={"bottom"}
        autoDismiss={5000}
        onDismiss={onDismissToast}
        backgroundColor={Colors.red40}
      /> */}

      <View
        style={{
          // backgroundColor: "orange",
          height: 100,
          width: 100,
          position: "absolute",
          bottom: 0,
          alignSelf: "center",
        }}
      >
        <Image
          source={RTLALogo}
          resizeMode="contain"
          style={{ width: "100%" }}
        />
      </View>

      <Portal>
          <Snackbar
            duration={2000}
            visible={!!visible}
            onDismiss={() => setVisible(null)}
          >
            {visible}
          </Snackbar>
        </Portal>
    </ScrollView>
    // </LinearGradient>
  );
};

export default Login;

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: "#063360",
    flex: 1,
    justifyContent: "center",
  },
  logoStyle: { width: 200, height: 60, alignSelf: "center" },
  inputContainerStyle: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#ddd",
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  signinBtn: {
    marginTop: 20,
  },
});
