import {
  ImageURISource,
  StyleProp,
  StyleSheet,
  ViewStyle,
  Image,
  ImageStyle,
} from "react-native";
import { View, Text } from "./Themed";
import React from "react";
import { ColorValue } from "react-native";

type AvatarProps = {
  source?: ImageURISource;
  style?: StyleProp<ImageStyle>;
  size?: number | undefined;
  name?: string | undefined;
  backgroundColor?: ColorValue | undefined;
  children?: React.ReactElement;
  containerStyle?: StyleProp<ViewStyle>;
};
const Avatar = ({
  size,
  style,
  source,
  name,
  children,
  backgroundColor,
  containerStyle,
}: AvatarProps) => {
  const width = size || 50;
  const height = size || 50;
  return (
    <View style={[styles.avatar, containerStyle]}>
      {source ? (
        <Image
          source={source}
          style={[styles.image, style, { backgroundColor, width, height }]}
        />
      ) : name ? (
        <Text>{name}</Text>
      ) : (
        children
      )}
    </View>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 100,
    height: 50,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#222",
    display: "flex",
  },
  image: {
    borderRadius: 100,
  },
});
