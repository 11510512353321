// import { useAppSelector } from "./redux";
import { AllThemes } from "../Constants";
import { useAppSelector } from "./redux";

export default function useAppTheme() {
  const theme = useAppSelector((state) => state.theme);
  // const { colorMode } = useColorMode();
  const t = AllThemes["dark"];

  return t;
}
