/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type RootStackParamList = {
  Welcome: undefined;
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  Settings: undefined;
  Support: undefined;
  Security: undefined;
  Notifications: undefined;
  ViewReports: { state: string; lga: string };
  ViewProjects: { state: string; lga: string };
  SearchProjects: { data: Report[] };
  ViewProject: { id: number };
  ViewState: { state: string };
  CreateReport: { sectorId?: number };
  AddProject: { report?: Report };
  MyReports: undefined;
  Login: undefined;
  ForgotPassword: undefined;
  SelectCategory: undefined;
  GroupChat: { state: string };
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>;

export type RootTabParamList = {
  ProjectsTab: undefined;
  HomeTab: undefined;
  LivestreamTab: undefined;
  ChatTab: undefined;
  SettingsTab: undefined;
};

// export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
//   NativeStackScreenProps<RootStackParamList>;
export type RootTabScreenProps<T extends keyof RootTabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<RootTabParamList, T>,
    RootStackScreenProps<keyof RootStackParamList>
  >;

export type AuthStackParamList = {
  Login: undefined;
};

export type AuthStackScreenProps<Screen extends keyof AuthStackParamList> =
  NativeStackScreenProps<AuthStackParamList, Screen>;

export interface NotificationObj {
  id: number;
  title: string;
  body: string;
  data: Record<"id", number>;
  date: number;
  read: boolean;
}

export interface User {
  id: number;
  // id: number;
  // created_at?: string;
  // createdAt?: string;
  // updatedAt?: string;
  // name: string;
  email: string;
  uid: string;
  // emailVerified?: boolean;
  // pushToken?: string;
  photo: string | null;
  firstName: string;
  lastName: string;
  lga: string;
  state: string;
  role: Role;
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  lga: string;
  state: string;
  photo: string;
  role: Role;
}

export type Role = Roles.admin | Roles.profo | Roles.superadmin;

export enum Roles {
  admin = "admin",
  profo = "profo",
  superadmin = "superadmin",
}

export interface Report {
  // createdAt: string;
  id?: number;
  created_at?: string;
  projectTitle: string;
  lga: string;
  state: string;
  sector: string;
  contractor: string;
  supervisor: string;
  pictures: string[];
  lat: number;
  lon: number;
  // location: [lat:string, lon:string];
  status: string;
  uid: string;
  verified: boolean;

  // updatedAt: string;
  // date: string;
  // categoryId: string;
  // fieldEntries: { [id: string]: any };
}

export type QuestionsAndAnswers = {
  id: number;
  questions: string;
  answers: string;
  sector: string;
  uid: string;
  state: string;
  lga: string;
};

export interface Complaint {
  category: string;
  subject: string;
  description: string;
  createdAt: string;
}

export interface IMessage {
  id?: number;
  _id: string;
  createdAt: number;
  text: string;
  created_at?: string | null;
  user: ChatUser;
  image?: string;
  video?: string;
  chatId: string;
  audio?: string;
  system: boolean;
  sent: boolean;
  received: boolean;
  pending: boolean;
  quickReplies?: QuickReplies | undefined;
}

export interface ChatUser {
  _id: string;
  name: string;
  avatar: string | undefined;
}
interface Reply {
  title: string;
  value: string;
  messageId?: any;
}

interface QuickReplies {
  type: "radio" | "checkbox";
  values: Reply[];
  keepIt?: boolean;
}

export type Theme = Themes.black | Themes.dark | Themes.classic | Themes.modern;
// | Themes.cool
// | Themes.nature;

export enum Themes {
  black = "black",
  dark = "dark",
  classic = "classic",
  modern = "modern",
  // cool = "cool",
  // nature = "nature",
}

export interface ExtendedTheme {
  dark: boolean;
  colors: {
    primary: string;
    background: string;
    card: string;
    text: string;
    border: string;
    inputBorder?: string;
    notification: string;
    textMuted: string;
    surface: string;
    bottomSheet: string;
    disabledText: string;
  };
}
