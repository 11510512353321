import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
  Theme,
} from "@react-navigation/native";
import { ExtendedTheme } from "./types";

export type Category = {
  title: string;
  image: any;
  description: string;
  likelyUnit: string;
  color?: string;
  iconName?: any;
  iconFamily?: string;
};

export const SECTORS_DETAILS = [
  {
    id: 1,
    name: "Youth Development & Job Creation",
    short: "Youth Dev & Job Creation",
    img: require("./assets/sectors/youth2.jpeg"),
    description:
      "This indicator aims to monitor the promotion of sustained, inclusive and sustainable economic growth, productive employment and decent work for all, facilitate the development of MSMSEs including the provision of resilient structure and promote inclusive/sustainable business enterprises development.",
    questions: [
      {
        type: "boolean",
        text: "Did the current administration of your state create job opportunities for individuals in your LGA this month?",
      },
      {
        type: "boolean",
        text: "Are there strategies employed by the current administration of your state to foster innovation and entrepreneurship, particularly in sectors such as technology and startups in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "input",
        text: "How many SME Operators were trained by the current administration in your state  this month?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state organized any program to simplify and enlighten individuals on the process of running a business in your LGA this month?",
      },
      { type: "input", text: "If yes, kindly state the program(s)" },
      {
        type: "boolean",
        text: "Are there measures put in place by the current administration in your state to enhance access to capital and financing options for small and medium-sized enterprises (SMEs) and aspiring entrepreneurs in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there strategies put in place by the government to address any existing barriers or challenges to trade, both domestically and internationally?",
      },
      { type: "input", text: "If yes, what are the strategies?" },
      {
        type: "boolean",
        text: "Are there policies or measures put in place by the government to support local industries and promote domestic production, while also encouraging international trade in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
    ],
  },
  {
    id: 2,
    name: "Critical Infrastructure & Manufacturing",
    short: "Critical Infra. & Man.",
    img: require("./assets/sectors/roads.jpeg"),
    description:
      "The aim of this indicator is to provide good critical infrastructures in order to facilitate and enhance socio-economic development of the State and to develop effective and reliable infrastructures.",

    questions: [
      {
        type: "boolean",
        text: "Are there new roads that were built in your LGA by the current administration?",
      },
      { type: "input", text: "If yes, kindly state the locations" },
      {
        type: "boolean",
        text: "Are there bad roads that were renovated in your LGA by the current administration?",
      },
      { type: "input", text: "If yes, kindly state the locations" },
      {
        type: "boolean",
        text: "Is the government protecting the critical infrastructures in your LGA from cyber threats?",
      },
      {
        type: "boolean",
        text: "Has the government improved the transportation infrastructures in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the government maintained and addressed the risks associated with the construction of critical infrastructures in your LGA?",
      },
      {
        type: "boolean",
        text: "Have pedestrian bridges been built by the current administration of your state in densely populated areas in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there adequate infrastructures provided by the current administration of your state  in schools in your community?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state addressed the issue of poor access to education, for marginalized and underprivileged persons in your LGA?",
      },
    ],
  },
  {
    id: 3,
    name: "Digital Economy",
    short: "Digital Economy",
    img: require("./assets/sectors/digital-economy.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration in your state to promote digital literacy and skills development among citizens in your LGA to ensure they can actively participate in the digital economy?",
      },
      {
        type: "input",
        text: "If yes, kindly state the measures",
      },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to promote digital inclusion and ensure that marginalized groups, such as women, minorities, and persons with disabilities, have equal opportunities in the digital economy?",
      },
      {
        type: "input",
        text: "If yes, kindly state the measures",
      },
      {
        type: "boolean",
        text: "Has the current administration organised programs in your LGA to encourage innovation and entrepreneurship in the digital sector, particularly for startups and small businesses?",
      },
      {
        type: "input",
        text: "If yes, kindly state the programs",
      },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to encourage digital innovation in traditional industries, such as healthcare, agriculture, and manufacturing, to drive productivity and economic growth in your LGA?",
      },
      {
        type: "input",
        text: "If yes, kindly state the measures",
      },
      {
        type: "boolean",
        text: "Has the current administration organised programs to encourage innovation and entrepreneurship in the digital sector, particularly among small and medium-sized enterprises in your LGA?",
      },
      {
        type: "input",
        text: "If yes, kindly state the programs",
      },
      {
        type: "boolean",
        text: "Are there strategies emploed by the current administration to to enhance digital literacy and skills training to equip workforce for future jobs in your LGA?",
      },
      {
        type: "input",
        text: "If yes, kindly state the strategies",
      },
    ],
  },
  {
    id: 4,
    name: "Education",
    short: "Education",
    description:
      "This indicator aims to monitor the provision of qualitative and functional education to the people in your community in an equitable manner with lasting improvements in the teaching and learning outcomes.",
    img: require("./assets/sectors/education.jpeg"),
    questions: [
      {
        type: "input",
        text: "How many new schools has the current administration built  in your LGA?",
      },
      {
        type: "input",
        text: "How many schools were renovated by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "Is there an increase in the percentage of students that go to school in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there adequate infrastructures provided by the current administration of your state  in schools in your community?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state addressed the issue of poor access to education, for marginalized and underprivileged persons in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state provided training and professional development opportunities for teachers in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state provided vocational training and skill development programs provided to foster creativity, critical thinking, and problem-solving skills amongst students in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state improved the infrastructure and facilities in primary schools, secondary schools, and tertiary institutions in your LGA? ",
      },
      {
        type: "boolean",
        text: "Is there inclusive/equal access and opportunities to education provided by the current administration for all students in your LGA?",
      },
    ],
  },
  {
    id: 5,
    name: "Healthcare",
    short: "Healthcare",
    description:
      "The aim of this indicator is to monitor the health status of the community members in Sapele, Delta State in a sustainable manner, through improved accessibility to affordable and qualitative healthcare services, reduction in health and disease burden among the people, and other targeted intervention programs specifically aimed at the attainment of the health-related SDGs.",
    img: require("./assets/sectors/healthcare.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Are there new Primary Health Care facilities built by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there new hospitals built by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there health facilities and structures renovated by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "Has access to healthcare services for all citizens been improved by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "5.Have more Health Care Providers been employed by the current administration in government health care facilities in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration taken into consideration the healthcare needs of vulnerable populations, including children, the elderly, and people with disabilities in your LGA?",
      },
    ],
  },
  {
    id: 6,
    name: "Energy",
    short: "Energy",
    img: require("./assets/sectors/energy.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Are there measures provided by the current administration in your LGA to reduce greenhouse gas emissions for transitioning towards a more sustainable future?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there measures provided by the current administration to make energy more affordable and accessible to all citizens in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there programs povided by the current administration to encourage energy efficiency and conservation?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
      {
        type: "boolean",
        text: "Are there measures provided by the current administration to promote research and development of new clean energy technologies?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there initiatives the current administration undertook to create jobs in the renewable energy sector in your LGA?",
      },
      { type: "input", text: " If yes, kindly state the initiatives" },
      {
        type: "boolean",
        text: "Are there projects on energy being implemented by the current administration in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the projects" },
      {
        type: "boolean",
        text: "If yes, were the local communities in your LGA involved in the decision-making processes of the energy projects?",
      },
    ],
  },
  {
    id: 7,
    name: "Environment",
    short: "Environment",
    description:
      "This indicator aims to monitor environmental exploitation for economic development regenerate and protect the environment for present and future generations.",
    img: require("./assets/sectors/environment.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Are issues of air and water pollution being addressed by the current administration in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to ensure clean and healthy environments for all in your LGA?",
      },
      {
        type: "boolean",
        text: "Are citizens and local communities involved in decision-making processes related to environmental issues in your LGA?",
      },
      {
        type: "boolean",
        text: "Has there been awareness programs instituted by the current administration in schools on preserving the environment?",
      },
      { type: "input", text: "If yes, kindly state the program(s)" },
      {
        type: "boolean",
        text: "Has the current administration in your state instituted proper landscaping in your environment?",
      },
      {
        type: "boolean",
        text: "Has there been measures instituted by the current administration to mitigate flooding disaster that leads to environmental degradation in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Has the current administration in your state instituted any program that supports climate change?",
      },
      {
        type: "boolean",
        text: "Has the current administration in your state instituted any program that targets GenZs and millennials on preserving the environment in your LGA?",
      },
    ],
  },
  {
    id: 8,
    name: "Agriculture",
    short: "Agriculture",
    description:
      "This indicator aims to improve the attainment of food security through increased agricultural productivity (including nutrition for women and children), the eradication of extreme poverty by significantly contributing to wealth creation and employment generation, particularly among the youths and rural subsistence farmers.",
    img: require("./assets/sectors/agriculture.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Has the current administration provided affordable and high-quality agricultural inputs, such as seeds, fertilizers, and pesticides for farmers in your community?",
      },
      {
        type: "boolean",
        text: "Has the current administration provided support to promote sustainable agriculture and reduce the use of harmful chemicals and pesticides in your community?",
      },
      {
        type: "boolean",
        text: "Is the current administration of your state tackling the issue of food security and ensuring access to nutritious food for individuals in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration organised trainings to encourage young people to pursue careers in agriculture and support the next generation of farmers in your LGA?",
      },
      {
        type: "boolean",
        text: "Has the current administration put in measures to facilitate agricultural trade in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Has the current administration put in measures or policies to encourage the adoption of modern technology for agriculture in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Has the current administration of your State instituted a loan facility for farmers in your LGA that you are aware of? ",
      },
      { type: "input", text: "If yes, kindly give more details" },
      {
        type: "boolean",
        text: "Has the current administration built new markets in your LGA to enhance the sales of agricultural products?",
      },
      {
        type: "boolean",
        text: "Has the current administration built new roads in your LGA to enhance the transportation of agricultural products to the market for sales?",
      },
    ],
  },
  {
    id: 9,
    name: "Security",
    short: "Security",
    img: require("./assets/sectors/security.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Did the current administration in your state put measures in place to strengthen border security in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there measures put in place by the current administration in your state to ensure the safety and security of citizens in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Has there been new equipment purchased by the current administration in your state for the police that is evident in your LGA? ",
      },
      { type: "input", text: "If yes, kindly list the equipments" },
      {
        type: "boolean",
        text: "Are there police stations in your LGA that have been renovated by the current administration?",
      },
      { type: "input", text: "If yes, how many" },
      {
        type: "boolean",
        text: "Are there programs instituted by the current administration to enlighten citizens on security?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
    ],
  },
  {
    id: 10,
    name: "Women & Girls Inclusion",
    short: "Women & Girls Incl.",
    description: "",
    img: require("./assets/sectors/women.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to prioritize women's rights in policies and decision-making processes in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Has the current administration organized programs to promote and support women's economic empowerment, including entrepreneurship and access to finance  in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to ensure equal representation of women in positions of power and leadership, both in government and  the private sector in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to improve women's access to quality education, particularly in underprivileged areas in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there measures instituted by the current administration to enhance women's health services and ensure access to affordable and comprehensive healthcare, including reproductive healthcare?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there strategies instituted by the current administration to promote women's participation in science, technology, engineering, and mathematics (STEM) fields in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there strategies instituted by the current administration address the specific challenges faced by marginalized groups of women, such as women with disabilities, indigenous women in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there policies such as affordable childcare, flexible work arrangements, and parental leave instituted by the current administration to support working mothers in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the policies" },
      {
        type: "boolean",
        text: "Are there initiatives instituted by the current administration to promote women's representation in politics and leadership/decision-making positions in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the initiatives" },
      {
        type: "boolean",
        text: "Are there skill acquistion and empowerment programs instituted by the current administration for women in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
    ],
  },
  {
    id: 11,
    name: "Water",
    short: "Water",
    description:
      "This indicator aims to achieve universal access to safe drinking water and sanitation services in the community, safeguarding the health of the public and ensuring that the people live a more qualitative, and productive life.",
    img: require("./assets/sectors/water.jpeg"),
    questions: [
      {
        type: "boolean",
        text: "Did the current administration provide access to clean and safe drinking water for all residents in your LGA?",
      },
      {
        type: "boolean",
        text: "Did the current administration build new boreholes in your LGA?",
      },
      {
        type: "input",
        text: "If yes, How many boreholes were built in your LGA?",
      },
      {
        type: "boolean",
        text: "Are there measures provided by the current administration to improve the quality of existing water sources and prevent contamination in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there measures provided by the current administration to improve sanitation facilities in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there programs instituted by the current administration to raise awareness about proper sanitation practices and hygiene education in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
      {
        type: "boolean",
        text: "Are there measures set up by the current administration to address the issue of solid waste management in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the measures" },
      {
        type: "boolean",
        text: "Are there mechanisms to monitor the progress of water and sanitation initiatives in your LGA?",
      },
      { type: "input", text: "If yes, kindly state the mechanisms" },
      {
        type: "boolean",
        text: "Are there awareness programs to enlighten the public on good measures for water and sanitation?",
      },
      { type: "input", text: "If yes, kindly state the programs" },
      {
        type: "boolean",
        text: "Has the current administration instituted a public sanitation exercise day in your LGA?",
      },
      {
        type: "input",
        text: "If yes, is it adhered to in your LGA and how is it implemented ?",
      },
    ],
  },
];

export const BlackTheme: ExtendedTheme = {
  // ...PaperDarkTheme,
  ...NavigationDarkTheme,
  dark: true,
  colors: {
    ...NavigationDarkTheme.colors,
    background: "rgba(40,45,60,1)",
    primary: "#0a91b2",
    card: "#0a0a0a",
    border: "#222222",
    // text: "#ffffff",
    textMuted: "#999",
    surface: "#0a0a0a",
    inputBorder: "#444",
    bottomSheet: "#333333",
    disabledText: "#222222",
  },
};

export const DarkTheme: ExtendedTheme = {
  ...NavigationDarkTheme,
  dark: true,
  colors: {
    ...NavigationDarkTheme.colors,
    card: "#1c1c1c",
    background: "rgba(40,45,60,1)",
    primary: "#f12929",
    border: "#555",
    surface: "#2c3333",
    textMuted: "#aaa",
    bottomSheet: "#111111",
    inputBorder: "#fff",
    disabledText: "#222222",
  },
};

export const CoolTheme: ExtendedTheme = {
  ...NavigationDefaultTheme,
  dark: true,
  colors: {
    ...NavigationDefaultTheme.colors,
    card: "#4C0033",
    background: "#790252",
    text: "#ffffff",
    border: "#910d66",
    inputBorder: "",
    primary: "#910d66",
    surface: "#4C0033",
    textMuted: "#eeeeee",
    bottomSheet: "#222222",
    disabledText: "#222222",
  },
};

export const NatureTheme: ExtendedTheme = {
  ...NavigationDefaultTheme,
  dark: false,
  colors: {
    ...NavigationDefaultTheme.colors,
    primary: "#609966",
    card: "#609966",
    background: "#9DC08B",
    border: "#EDF1D6",
    text: "#ffffff",
    surface: "#609966",
    textMuted: "#eeeeee",
    bottomSheet: "#333333",
    disabledText: "#222222",
  },
};

export const ModernTheme: ExtendedTheme = {
  ...NavigationDefaultTheme,
  dark: true,
  colors: {
    ...NavigationDefaultTheme.colors,
    primary: "#609966",
    card: "#002E94",
    background: "#083AA9",
    border: "#002E94",
    text: "#ffffff",
    surface: "#002E94",
    textMuted: "#ffffff",
    bottomSheet: "#222222",
    disabledText: "#222222",
  },
};

export const DefaultTheme: ExtendedTheme = {
  ...NavigationDefaultTheme,
  dark: false,
  colors: {
    ...NavigationDefaultTheme.colors,
    background: "#ffffff",
    card: "#eeeeee",
    // secondary: '#f12626',
    primary: "#063360",
    surface: "#ffffff",
    text: "#000000",
    textMuted: "#777777",
    bottomSheet: "#eeeeee",
    border: "#f3eded",
    inputBorder: "#aaa",
    disabledText: "#777777",
  },
};

export const AllThemes: { [n: string]: ExtendedTheme } = {
  black: BlackTheme,
  dark: DarkTheme,
  classic: DefaultTheme,
  cool: CoolTheme,
  nature: NatureTheme,
  modern: ModernTheme,
};

export const INT_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const THEMES = ["dark", "deep", "classic", "purple", "cool", "modern"];

export const activeStates = [
  "Sokoto",
  "Gombe",
  "Plateau",
  "Ebonyi",
  "Ekiti",
  "Akwa Ibom",
];

export const activeStatesData = [
  { name: "Sokoto", image: require("./assets/states/sokoto.jpeg") },
  { name: "Gombe", image: require("./assets/states/gombe.jpeg") },
  { name: "Plateau", image: require("./assets/states/plateau.jpeg") },
  { name: "Ebonyi", image: require("./assets/states/ebonyi.jpeg") },
  { name: "Ekiti", image: require("./assets/states/ekiti.jpeg") },
  { name: "Akwa Ibom", image: require("./assets/states/akwaibom.jpeg") },
];

export const SECTORS = [
  "Youth Development & Job Creation",
  "Critical Infrastructure & Manufacturing",
  "Digital Economy",
  "Education",
  "Healthcare",
  "Environment & Energy",
  "Security",
  "Women & Girls Inclusion",
];
