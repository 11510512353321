import { ScrollView, StyleSheet, Pressable, Image } from "react-native";
import { View } from "../components/Themed";
import React, { useEffect, useRef } from "react";
import { QuestionsAndAnswers, RootTabScreenProps } from "../types";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { List, Text, useTheme, Button } from "react-native-paper";
import { activeStatesData } from "../Constants";
import { supabase } from "../lib/superbase";
import { setQuestion } from "../reducers/reportsReducer";
import { convertToList } from "../utils/convertDataStructures";
import Header from "../components/Header";
import ActionSheet, { ActionSheetRef } from "react-native-actions-sheet";

type ProjectsProps = RootTabScreenProps<"ProjectsTab">;
const Projects = ({ navigation }: ProjectsProps) => {
  const Dispatch = useAppDispatch();
  const theme = useTheme();
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const auth = useAppSelector((state) => state.auth);
  const user = auth.user;

  const openState = (state: string) => {
    navigation.navigate("ViewState", {
      state,
    });
  };

  useEffect(() => {
    async function getResponses() {
      const { data } = await supabase
        .from("questions_and_answers")
        .select()
        .eq("uid", user?.uid!);
      const d = data as QuestionsAndAnswers[];

      if (data && data?.length > 0) {
        Dispatch(setQuestion(convertToList(d, "sector")));
      }
    }

    getResponses();
  }, []);

  const CardItem = ({ item }: { item: { name: string; image: any } }) => {
    return (
      <Pressable
        style={{ width: "48%", marginBottom: 20 }}
        onPress={() => openState(item?.name)}
      >
        <>
          <Image
            source={item?.image}
            style={{
              width: "100%",
              height: 200,
              marginBottom: 5,
              borderRadius: 5,
            }}
          />
          <Text>{item?.name} State</Text>
        </>
      </Pressable>
    );
  };

  return (
    <View style={{ position: "relative" }}>
      <Header
        title="Projects"
        onPressBack={() => {}}
        showBack={false}
        backgroundColor={theme.colors?.surface}
        right={
          <>
            {user?.role === "profo" && (
              <Button
                icon={"plus"}
                textColor="#fff"
                mode="text"
                onPress={() => actionSheetRef.current?.show()}
              >
                New
              </Button>
            )}
          </>
        }
      />

      <ScrollView contentContainerStyle={styles.scrollView}>
        <View
          paddingHorizontal={15}
          display={"flex"}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          columnGap={10}
        >
          {activeStatesData.map((state, i) => {
            return <CardItem key={i} item={state} />;
          })}
        </View>
      </ScrollView>

      <ActionSheet
        ref={actionSheetRef}
        containerStyle={{ backgroundColor: theme.colors?.background }}
      >
        <List.Item
          left={(props) => <List.Icon {...props} icon="plus" />}
          title="New Project"
          onPress={() => {
            actionSheetRef?.current?.hide();
            navigation.navigate("AddProject", { report: undefined });
          }}
        ></List.Item>
        <List.Item
          left={(props) => <List.Icon {...props} icon="plus-box" />}
          title="New Report"
          onPress={() => {
            actionSheetRef?.current?.hide();
            navigation.navigate("SelectCategory");
          }}
        ></List.Item>
      </ActionSheet>
    </View>
  );
};

export default Projects;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    paddingTop: 15,
    flexGrow: 1,
    paddingBottom: 105,
    // height: 200,
    // flex: 1,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 60,
    backgroundColor: "red",
    zIndex: 100,
  },
  leftFab: {
    position: "absolute",
    margin: 16,
    left: 0,
    bottom: 60,
    backgroundColor: "#fff",
    zIndex: 100,
  },
});
