import {
  ButtonProps,
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TouchableOpacityProps,
  ActivityIndicator,
  ColorValue,
} from "react-native";
import React from "react";
import { Text } from "./Themed";
import useAppTheme from "../hooks/theme";

interface Props extends TouchableOpacityProps {
  title?: string | undefined;
  variant?: "outlined" | "primary" | "ghost"; //"ghost" | "subtle";
  style?: StyleProp<ViewStyle>;
  rounded?: boolean | undefined;
  isLoading?: boolean | undefined;
  textColor?: ColorValue | "transparent";
  children?: string | React.ReactElement;
}
const Button = ({
  title,
  variant = "primary",
  style,
  rounded,
  isLoading,
  children,
  disabled,
  textColor,
  ...rest
}: Props) => {
  const ThemeColors = useAppTheme()?.colors;
  const backgroundColor =
    variant === "primary"
      ? disabled
        ? ThemeColors?.inputBorder
        : ThemeColors?.primary
      : variant === "ghost"
      ? "transparent"
      : ThemeColors?.card;
  const borderRadius = rounded ? 100 : 5;

  return (
    <TouchableOpacity
      style={[
        styles.base,
        { borderRadius },
        styles[variant],
        { backgroundColor },
        style,
      ]}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator />
      ) : title ? (
        <Text
          style={[
            styles.text,
            {
              color: textColor
                ? textColor
                : variant === "primary"
                ? "#ffffff"
                : ThemeColors?.text,
            },
          ]}
        >
          {title}
        </Text>
      ) : typeof children === "string" ? (
        <Text
          color={
            textColor
              ? textColor
              : variant === "primary"
              ? "#fff"
              : ThemeColors?.text
          }
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  base: {
    borderRadius: 5,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  primary: {
    // borderRadius: 10,
    // paddingVertical: 5,
  },
  ghost: {},
  outlined: {
    borderWidth: 1,
    borderRadius: 10,
  },
  text: {
    textAlign: "center",
  },
});
