import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { View, Text } from "../components/Themed";

import React, { useEffect, useState } from "react";
import { QuestionsAndAnswers, Report, RootStackParamList } from "../types";

import Header from "../components/Header";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { activeStates, SECTORS_DETAILS } from "../Constants";
import { supabase } from "../lib/superbase";
import Box from "../components/Box";
import { ActivityIndicator } from "react-native-paper";

type ViewReportsProps = NativeStackScreenProps<
  RootStackParamList,
  "ViewReports"
>;
const ViewReports = ({ navigation, route }: ViewReportsProps) => {
  const state = route.params.state;
  const lga = route.params.lga;
  const title = `Reports in ${lga}, ${state}`;

  const [allReports, setAllReports] = useState<QuestionsAndAnswers[] | null>(
    null
  );

  useEffect(() => {
    const getReports = async () => {
      const { data } = await supabase
        .from("questions_and_answers")
        .select()
        .eq("state", state)
        .eq("lga", lga);
      // .eq("verified", true);

      if (data && data?.length > 0) {
        const d = data.map((a) => {
          return { ...a, created_at: a.created_at || undefined };
        }) as unknown as QuestionsAndAnswers[];

        const reports = d
          .filter((c) => c.lga === lga && c.state === state)
          .map((r) => ({
            ...r,
            answers: JSON.parse(r.answers),
            questions: JSON.parse(r.questions),
          }));

        setAllReports(reports);
      }

      if (data && data?.length === 0) {
        setAllReports([]);
      }
    };

    if (state && activeStates.includes(state)) {
      getReports();
    }
  }, [state, lga]);

  const ChipItem = React.memo(
    ({
      label,
      selected,
      onPress,
    }: {
      label: string;
      selected: boolean;
      onPress: () => void;
    }) => {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={{
            paddingHorizontal: 10,
            paddingVertical: 6,
            marginRight: 10,
            borderRadius: 20,
            backgroundColor: selected ? "#ffffff" : "rgba(255, 255, 255,.1)",
          }}
        >
          <Text style={{ color: selected ? "#000" : "white" }}>{label}</Text>
        </TouchableOpacity>
      );
    }
  );

  const ParentComponent = () => {
    const [selectedSector, setSelectedSector] = useState(
      SECTORS_DETAILS[0].name
      // "Agriculture"
    );
    const currReport =
      allReports?.filter((p) =>
        selectedSector ? p.sector === selectedSector : true
      )?.[0] || {};

    const handleChipPress = (sector: string) => {
      // setSelectedSector(sector);

      setSelectedSector(sector); // Select the item
    };

    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ScrollView horizontal contentContainerStyle={{ marginBottom: 20 }}>
          {SECTORS_DETAILS.map((s) => (
            <ChipItem
              key={s.id}
              label={s.name}
              selected={selectedSector === s.name}
              onPress={() => handleChipPress(s.name)}
            />
          ))}
        </ScrollView>

        <View display={"flex"}>
          {currReport?.questions?.map((question, i) => (
            <View
              marginTop={question?.type == "boolean" ? 15 : 5}
              padding={15}
              key={i}
              borderWidth={0.5}
              borderColor="#195A93"
              borderTopRightRadius={15}
              borderTopLeftRadius={15}
            >
              <Text>Question:</Text>
              <Text
                fontWeight="bold"
                marginBottom={15}
                fontSize={18}
                padding={10}
              >
                {question?.text}
              </Text>

              <Text paddingLeft={10}>Answer:</Text>
              <Text textTransform="capitalize" padding={15}>
                {currReport.answers[i]}
              </Text>
            </View>
          ))}

          {allReports == null ? (
            <Box
              justifyContent="center"
              alignItems="center"
              height={150}
              width="100%"
              display="flex"
            >
              <ActivityIndicator color="#fff" />
            </Box>
          ) : (
            !currReport?.id && <Text>No reports yet for this sector</Text>
          )}
        </View>
      </ScrollView>
    );
  };

  return (
    <View flex={1}>
      <Header
        title={title}
        onPressBack={() => navigation.goBack()}
        backgroundColor="transparent"
      />

      <ParentComponent />
    </View>
  );
};

export default ViewReports;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    padding: 15,
  },
});
