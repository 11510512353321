import { ScrollView, StyleSheet } from "react-native";
import { Avatar } from "react-native-paper";
import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import { View, Text } from "../components/Themed";
import { SECTORS, SECTORS_DETAILS } from "../Constants";
import { TouchableOpacity } from "react-native";

const SelectCategory = ({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "SelectCategory">) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text fontSize={20} style={styles.title} color={"#ccc"}>
        Select a sector
      </Text>

      <View
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        rowGap={30}
        columnGap={5}
        justifyContent="space-between"
        paddingBottom={50}
      >
        {SECTORS_DETAILS.map((sector) => (
          <View
            style={styles.block}
            width={"30.33%"}
            key={sector?.name}
            alignItems="center"
          >
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("CreateReport", { sectorId: sector?.id })
              }
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar.Image source={sector.img} size={70} />
              <Text textAlign="center" color={"#ddd"} marginTop={8}>
                {sector?.name}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

export default SelectCategory;

const styles = StyleSheet.create({
  container: {
    padding: 15,
    flex: 1,
    paddingBottom: 50,
  },
  title: {
    marginBottom: 20,
  },
  block: {},
});
