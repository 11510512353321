import { createClient } from "@supabase/supabase-js";
import { Database } from "./database.types";
import AsyncStorage from "@react-native-async-storage/async-storage";

const options = {
  schema: "public",
  // headers: { 'x-my-custom-header': 'my-app-name' },
  // autoRefreshToken: true,
  // persistSession: true,
  // detectSessionInUrl: true,
  auth: {
    storage: AsyncStorage as any,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
};
const supabase = createClient<Database>(
  "https://gbsdrtsjmdakgsgqfgru.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imdic2RydHNqbWRha2dzZ3FmZ3J1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM3MjY4ODgsImV4cCI6MTk5OTMwMjg4OH0.QJ2AhcLzKTeCFBZd6eE_QachgNonZCfpNLQ2Apfw4Sg",
  options
);

export { supabase };
