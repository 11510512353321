import { ImageBackground, Image, StyleSheet, Dimensions } from "react-native";
import React from "react";
import { Button } from "react-native-paper";

const { width, height } = Dimensions.get("screen");

const WelcomeScreen = ({ navigation }: any) => {
  return (
    <ImageBackground
      source={require("../assets/welcome2.jpg")}
      style={{ height, width, justifyContent: "center", alignItems: "center" }}
    >
      <Image
        source={require("../assets/ftp-transparent.png")}
        style={{ width: 200, height: 90, position: "absolute", top: "10%" }}
      />
      <Button
        mode="contained"
        textColor="#fff"
        buttonColor="#063360"
        onPress={() => navigation.navigate("Root")}
      >
        Continue
      </Button>
    </ImageBackground>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({});
