import {
  ColorValue,
  StyleProp,
  StyleSheet,
  ViewProps,
  ViewStyle,
} from "react-native";
import { View } from "./Themed";
import React from "react";

interface ColumnProps extends ViewProps, ViewStyle {
  children?: React.ReactElement | React.ReactElement[] | undefined;
  // backgroundColor: ColorValue | undefined,
  // color: ColorValue | undefined,

  // flexDirection: 'row' | 'column',
  // style?: StyleProp<ViewStyle> | undefined;
}
const Column = ({ children, style, ...rest }: ColumnProps) => {
  return (
    <View {...rest} style={[style, { ...rest }, { flexDirection: "column" }]}>
      {children}
    </View>
  );
};

export default Column;

const styles = StyleSheet.create({});
