export const getRandomString = (len = 8) => {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const getRandomNumber = (len = 6) => {
  let num = "";
  const possible = "0123456789";

  for (let i = 0; i < len; i++) {
    num += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return parseInt(num);
};

/**
 * Generates a uuid
 * @return string
 */
export const generateUUID = () => {
  // -- StackOverflow
  var d = new Date().getTime(); // Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const truncateText = (text: string, limit: number) => {
  return text.length >= limit ? text.slice(0, limit) + "..." : text;
};
