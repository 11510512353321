import { Platform } from "react-native";
import { useEffect } from "react";

import { StatusBar } from "expo-status-bar";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import {
  PaperProvider,
  adaptNavigationTheme,
  MD3DarkTheme,
} from "react-native-paper";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Provider } from "react-redux";
import store from "./store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import Navigation from "./navigation";

import { Image } from "react-native";
import { Asset } from "expo-asset";
import { supabase } from "./lib/superbase";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

if (Platform.OS === "android") {
  Notifications.setNotificationChannelAsync("new_projects", {
    name: "new_projects",
    importance: Notifications.AndroidImportance.MAX,
    vibrationPattern: [0, 250, 250, 250],
    lightColor: "#FF231F7C",
  });

  Notifications.setNotificationCategoryAsync("new_project", [
    {
      identifier: "view",
      buttonTitle: "Check it out",
    },
  ]);
}

const persistor = persistStore(store);
export default function App() {
  const { DarkTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
  });

  // import "react-native-url-polyfill/auto";

  const CombinedDarkTheme = {
    ...MD3DarkTheme,
    ...DarkTheme,
    colors: {
      ...MD3DarkTheme.colors,
      ...DarkTheme.colors,
      primary: "red",
      background: "#063360",
      surface: "#04203d",
      // onSurface: "orange",
      outline: "#777",
      onSurfaceVariant: "#fff",
      primaryContainer: "red",
      onPrimaryContainer: "#000",
      secondary: "blue",
      onSecondary: "pink",
      card: "#04203d",
      // onSecondaryContainer: "darkblue",
      // customDarkTheme,
    },
  };

  function cacheImages(images: any[]) {
    return images.map((image) => {
      if (typeof image === "string") {
        return Image.prefetch(image);
      } else {
        return Asset.fromModule(image).downloadAsync();
      }
    });
  }

  async function registerForPushNotificationsAsync() {
    let token;

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);

      await AsyncStorage.setItem("@pushtoken", token);

      await supabase.from("pushtokens").insert({
        token,
      });
    } else {
      alert("Must use physical device for Push Notifications");
    }

    return token;
  }

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // const imageAssets = cacheImages([
        //   require("./assets/splash.png"),
        // ]);
        // // cacheFonts();
        // // await Promise.all([...imageAssets, ...fontAssets]);
        // await Promise.all([...imageAssets]);
      } catch (e) {
        console.warn(e);
      }
    }

    loadResourcesAndDataAsync();

    async function initPush() {
      const token = await AsyncStorage.getItem("@pushtoken");

      if (!token) {
        registerForPushNotificationsAsync();
      }
    }

    initPush();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StatusBar
          backgroundColor={CombinedDarkTheme?.colors?.surface}
          style="light"
        />
        <PaperProvider theme={CombinedDarkTheme}>
          <ActionSheetProvider>
            <Navigation theme={CombinedDarkTheme} />
          </ActionSheetProvider>
        </PaperProvider>
      </PersistGate>
    </Provider>
  );
}
