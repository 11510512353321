import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme, Themes } from "../types";

interface ThemeState {
  value: Theme;
}

const initialState: ThemeState = {
  value: Themes.dark,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.value = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
