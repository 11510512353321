import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMessage, User } from "../types";

interface Chat {
  groupChatData: { [uid: string]: IMessage };
  privateChats: { [uid: string]: IMessage };
  users: { [uid: string]: User };
}

const initialState: Chat = {
  groupChatData: {},
  privateChats: {},
  users: {},
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setGroupChat: (
      state,
      action: PayloadAction<{ [_id: string]: IMessage }>
    ) => {
      state.groupChatData = action.payload;
    },
    addGroupChatMessage: (state, action: PayloadAction<IMessage>) => {
      state.groupChatData[action.payload._id!] = action.payload;
    },
    setPrivateChatMessages: (
      state,
      action: PayloadAction<{ [id: string]: IMessage }>
    ) => {
      state.privateChats = action.payload;
    },
    addPrivateChatMessage: (state, action: PayloadAction<IMessage>) => {
      state.privateChats[action.payload._id] = action.payload;
    },
    setUsers: (state, action: PayloadAction<{ [id: string]: User }>) => {
      state.users = action.payload;
    },
  },
});

export const {
  addPrivateChatMessage,
  setPrivateChatMessages,
  setGroupChat,
  addGroupChatMessage,
  setUsers,
} = chatSlice.actions;

export default chatSlice.reducer;
