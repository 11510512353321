import { registerRootComponent } from "expo";
// import { Platform } from "react-native";

// import App from "./App";
import AppWeb from "./AppWeb";
// console.log(Platform.OS);

// const main = Platform.OS == "android" || Platform.OS == "ios" ? App : AppWeb;

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(AppWeb);
