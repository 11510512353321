import { StyleSheet, KeyboardAvoidingView, ScrollView } from "react-native";
import React, { useState } from "react";
import { View, Text } from "../components/Themed";
import Box from "../components/Box";
import Input from "../components/Input";
import { Button } from "react-native-paper";
// import ActionSheet, {
//   ActionSheetRef,
//   SheetManager,
// } from "react-native-actions-sheet";

import { RootStackParamList } from "../types";
import { supabase } from "../lib/superbase";
import { Snackbar } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

type SecurityProps = NativeStackScreenProps<RootStackParamList, "Security">;
const Security = (props: SecurityProps) => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", show: false });

  const changePassword = async () => {
    if (!password || password?.length <= 5) {
      setSnackbar({
        show: true,
        message: "Password should be at least 6 digits long",
      });

      return;
    }

    setIsLoading(true);

    try {
      await supabase.auth.updateUser({ password });
      setSnackbar({
        show: true,
        message: "Password updated successfully",
      });

      setIsLoading(false);
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Something went wrong. Please try again",
      });
    }
  };

  return (
    <View style={styles.container}>
      {/* <Header title="Security" onPressBack={() => navigate("/settings")} /> */}
      <ScrollView style={styles.scrollView}>
        <Box paddingHorizontal={5} style={{ width: "100%" }}>
          <KeyboardAvoidingView>
            <Text style={{ fontWeight: "bold" }}>Update password</Text>
            <Input
              style={{
                marginTop: 15,
                marginBottom: 25,
                // backgroundColor: "#222",
                borderRadius: 5,
              }}
              variant="outlined"
              onChangeText={setPassword}
              secureTextEntry={true}
            />
            <Button
              mode="contained"
              onPress={changePassword}
              loading={isLoading}
            >
              Update
            </Button>
          </KeyboardAvoidingView>
        </Box>
      </ScrollView>

      <Snackbar
        visible={snackbar.show}
        onDismiss={() => setSnackbar({ message: "", show: false })}
      >
        {snackbar?.message}
      </Snackbar>
    </View>
  );
};

export default Security;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    padding: 15,
  },
});
