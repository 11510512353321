import { Platform, ScrollView, StyleSheet } from "react-native";
import { View, Text } from "../components/Themed";
import Box from "../components/Box";
import { useState, useEffect } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RootStackParamList } from "../types";
import useAppTheme from "../hooks/theme";

import { Picker } from "@react-native-picker/picker";
import Button from "../components/Button";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { supabase } from "../lib/superbase";
import { addReport } from "../reducers/reportsReducer";

import * as ImagePicker from "expo-image-picker";
import { StatesAndLGA } from "../lib/states-and-lga";
import { activeStates, SECTORS_DETAILS } from "../Constants";
import { keyBy } from "lodash";
import { decode } from "base64-arraybuffer";
import * as Location from "expo-location";
import { Portal, Snackbar, TextInput } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

const AddProject = ({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "AddProject">) => {
  const ThemeColors = useAppTheme().colors;
  // const navigate = useNavigate();
  const Dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const s = StatesAndLGA.filter((s) => activeStates.includes(s.state));
  const states = keyBy(s, "state");

  const report = route.params?.report;

  const [sector, setSector] = useState(report?.sector || "Select");
  const [projectTitle, setProjectTitle] = useState(report?.projectTitle || "");
  const [state, setState] = useState(user?.state);
  const [lga, setLga] = useState(user?.lga);
  const [contractor, setContractor] = useState(report?.contractor || "");
  const [supervisor, setSupervisor] = useState(report?.supervisor || "");
  const [status, setStatus] = useState(report?.status || "Select");
  const [pictures, setPictures] = useState<ImagePicker.ImagePickerAsset[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<string | null>();

  let timeout: any;

  useEffect(() => {
    if (state) {
      setLga(states[state].lgas[0]);
    }
  }, [state]);

  const pickImageAsync = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      // allowsEditing: true,
      quality: 1,
      base64: true,
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.canceled) {
      setPictures(result.assets);
    } else {
      setVisible("You did not select any image.");
    }
  };

  async function uploadImgs(images: ImagePicker.ImagePickerAsset[]) {
    const imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      const imageName = `${Date.now()}`;

      const { data, error } = await supabase.storage
        .from("uploads")
        .upload(`projects/${imageName}.jpg`, decode(image.base64 || ""));

      if (error) {
        console.log(error);
      } else {
        imageUrls.push(
          "https://gbsdrtsjmdakgsgqfgru.supabase.co/storage/v1/object/public/uploads/" +
            data.path
        );
      }
    }

    return imageUrls;
  }

  const handleSubmit = async () => {
    if (!projectTitle) {
      setVisible("Please add a project title");
      return;
    }
    if (sector == "Select") {
      setVisible("Please select a sector");
      return;
    }
    if (!contractor) {
      setVisible("Please fill Contractor field");
      return;
    }
    if (!supervisor) {
      setVisible("Please fill Supervisor field");
      return;
    }
    if (pictures?.length == 0 && !report?.id) {
      setVisible("Please select at least 1 photo of the project");
      return;
    }
    if (status == "Select") {
      setVisible("Please select the project status");
      return;
    }

    setLoading(true);

    let lat = report?.lon || 0;
    let lon = report?.lat || 0;

    if (!report?.id) {
      let { status: locationGrantStatus } =
        await Location.requestForegroundPermissionsAsync();
      if (locationGrantStatus !== "granted") {
        setVisible("Permission to access location was denied");
      } else {
        let location = await Location.getCurrentPositionAsync({});

        lat = location.coords.latitude;
        lon = location.coords.longitude;
      }
    }

    if (report?.id) {
      //update report

      const uploaded = pictures?.length > 0 ? await uploadImgs(pictures) : [];
      // console.log({ uploaded });

      const { error } = await supabase
        .from("reports")
        .update({
          projectTitle,
          status,
          contractor,
          supervisor: supervisor || "",
          pictures: uploaded || [],
          sector,
        })
        .eq("id", report?.id);

      if (error) {
        setVisible(
          "Something went wrong. Please check your connection and try again"
        );

        setLoading(false);
        return;
      }

      Dispatch(
        addReport({
          id: report?.id,
          projectTitle,
          state: user?.state!,
          lga: user?.lga!,
          lat: report?.lat,
          lon: report?.lon,
          status,
          contractor,
          supervisor: supervisor || "",
          pictures: uploaded || [],
          sector,
          uid: user?.uid!,
          verified: report?.verified,
        })
      );

      setVisible("Report updated successfully");

      timeout = setTimeout(() => {
        navigation.goBack();
      }, 2000);
    } else {
      const uploaded = await uploadImgs(pictures);
      // console.log({ uploaded });

      const { data, error } = await supabase
        .from("reports")
        .insert({
          projectTitle,
          state: user?.state!,
          lga: user?.lga!,
          lat,
          lon,
          status,
          contractor,
          supervisor: supervisor || "",
          pictures: uploaded || [],
          sector,
          uid: user?.uid!,
        })
        .select();

      if (error) {
        setVisible(
          "Something went wrong. Please check your connection and try again"
        );

        setLoading(false);
        return;
      }

      Dispatch(
        addReport({
          id: data?.[0]?.id,
          projectTitle,
          state: user?.state!,
          lga: user?.lga!,
          lat,
          lon,
          status,
          contractor,
          supervisor: supervisor || "",
          pictures: uploaded || [],
          sector,
          uid: user?.uid!,
          verified: false,
        })
      );

      setVisible("Report submitted successfully");

      timeout = setTimeout(() => {
        navigation.goBack();
      }, 2000);
    }
  };

  useEffect(() => {
    return clearTimeout(timeout);
  }, []);

  const Label = ({ title }: { title: string }) => (
    <Text style={{ marginBottom: 5, marginTop: 20 }}>{title}</Text>
  );

  return (
    <View flex={1}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Label title="Project title" />
        <TextInput
          placeholder="Project title"
          mode="outlined"
          outlineStyle={{ borderRadius: 20 }}
          onChangeText={(v) => setProjectTitle(v)}
          defaultValue={projectTitle}
          placeholderTextColor={"#aaa"}
        />
        <Label title="Sector" />
        <Box borderColor={"#777"} borderWidth={1} borderRadius={20} padding={4}>
          <Picker
            selectedValue={sector}
            onValueChange={(itemValue, itemIndex) => setSector(itemValue)}
            // selectionColor={"#777"}
            dropdownIconColor={"#777"}
            style={{
              borderRadius: Platform?.OS === "web" ? 0 : 20,
              borderWidth: Platform?.OS === "web" ? 0 : 1,
              color: "#fff",
              backgroundColor: "transparent",
              padding: Platform?.OS === "web" ? 10 : 0,
              // backgroundColor: "#222532",
            }}
          >
            <Picker.Item label={"Select"} value={"Select"} />
            {SECTORS_DETAILS.map((sector) => (
              <Picker.Item
                key={sector.name}
                label={sector.name}
                value={sector.name}
              />
            ))}
          </Picker>
        </Box>

        <Label title="Contractor/Implementation Partner" />
        <TextInput
          placeholder="Contractor/Implementation Partner"
          mode="outlined"
          outlineStyle={{ borderRadius: 20 }}
          onChangeText={(v) => setContractor(v)}
          defaultValue={contractor}
          // style={{ borderColor: "#fff" }}
          placeholderTextColor={"#aaa"}
        />

        <Label title="Supervising Ministry" />
        <TextInput
          placeholder="Supervising Ministry"
          mode="outlined"
          outlineStyle={{ borderRadius: 20 }}
          onChangeText={(v) => setSupervisor(v)}
          defaultValue={supervisor}
          placeholderTextColor={"#aaa"}
        />

        <Label title="State" />
        <TextInput
          placeholder="State"
          mode="outlined"
          outlineStyle={{ borderRadius: 20, borderColor: "#777" }}
          // onChangeText={(v) => setSupervisor(v)}
          disabled
          defaultValue={state}
          placeholderTextColor={"#aaa"}
        />

        <Label title="LGA" />
        <TextInput
          placeholder="LGA"
          mode="outlined"
          outlineStyle={{ borderRadius: 20, borderColor: "#777" }}
          // onChangeText={(v) => setSupervisor(v)}
          disabled
          defaultValue={lga}
          placeholderTextColor={"#aaa"}
        />

        <Button
          style={{
            width: 200,
            marginTop: 20,
            flexDirection: "row",
            columnGap: 5,
            alignItems: "center",
            backgroundColor: "transparent",
            borderColor: ThemeColors?.primary,
            borderWidth: 1,
            borderRadius: 20,
          }}
          onPress={pickImageAsync}
        >
          <>
            <MaterialCommunityIcons
              name="camera"
              color={ThemeColors?.primary}
              size={20}
            />
            <Text color={ThemeColors?.text}>
              {pictures?.length
                ? pictures?.length + " Selected"
                : "Add pictures"}
            </Text>
          </>
        </Button>

        <Label title="Project Phase/Status" />
        <Box borderColor={"#777"} borderWidth={1} borderRadius={20} padding={4}>
          <Picker
            selectedValue={status}
            onValueChange={(itemValue, itemIndex) => setStatus(itemValue)}
            // selectionColor={"#777"}
            dropdownIconColor={"#777"}
            style={{
              borderRadius: Platform?.OS === "web" ? 0 : 20,
              borderWidth: Platform?.OS === "web" ? 0 : 1,
              color: "#fff",
              backgroundColor: "transparent",
              padding: Platform?.OS === "web" ? 10 : 0,
            }}
          >
            {/* <Picker.Item label="Started" value="started" /> */}
            <Picker.Item label="Select" value={"Select"} />
            <Picker.Item label="On going" value="on going" />
            <Picker.Item label="Abandoned" value="abandoned" />
            <Picker.Item label="Completed" value="completed" />
          </Picker>
        </Box>

        <Button
          style={{ marginTop: 30 }}
          rounded
          onPress={handleSubmit}
          isLoading={loading}
        >
          Submit
        </Button>

        <Portal>
          <Snackbar
            duration={2000}
            visible={!!visible}
            onDismiss={() => setVisible(null)}
          >
            {visible}
          </Snackbar>
        </Portal>
      </ScrollView>
    </View>
  );
};

export default AddProject;

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: 25,
    paddingTop: 10,
    // marginBottom: 20,
    paddingBottom: 20,
  },
});
