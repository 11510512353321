import {
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  StatusBar,
  RefreshControl,
  Platform,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";

import Row from "../components/Row";
import Box from "../components/Box";

import { Report, RootTabScreenProps } from "../types";
import { supabase } from "../lib/superbase";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { orderBy } from "lodash";
import { convertToArray, convertToList } from "../utils/convertDataStructures";
import { setReports } from "../reducers/reportsReducer";
// import { StatusBar } from "expo-status-bar";
import { View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { GestureHandlerRootView } from "react-native-gesture-handler";

import * as Notifications from "expo-notifications";

import {
  MD3Theme,
  Text,
  Badge,
  Button,
  useTheme,
  IconButton,
} from "react-native-paper";
import { addNotification } from "../reducers/notificationsReducer";
import { ArrowRight2, Notification } from "iconsax-react-native";

const PlaceholderImg = require("../assets/logo.jpeg");
const ChipItem = React.memo(
  ({
    label,
    selected,
    onPress,
  }: {
    label: string;
    selected: boolean;
    onPress: () => void;
  }) => {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={{
          paddingHorizontal: 10,
          paddingVertical: 6,
          marginRight: 10,
          borderRadius: 20,
          backgroundColor: "#fff",
          borderColor: selected ? "#ffffff" : "rgba(255, 255, 255,.1)",
        }}
      >
        <Text style={{ color: selected ? "red" : "#000" }}>{label}</Text>
      </TouchableOpacity>
    );
  }
);

const RecentReports = ({
  navigation,
}: {
  navigation: any;
  appTheme: MD3Theme;
}) => {
  const [selected, setSelected] = useState("");

  const [refreshing, setRefreshing] = useState(true);

  const Dispatch = useAppDispatch();

  const { reports } = useAppSelector((state) => state.report);

  const allReports = orderBy(convertToArray(reports), "id", "desc").filter(
    (r) => r.verified
  );

  const loadData = () => {
    setRefreshing(true);
    getReports();
  };

  useEffect(() => {
    getReports();
  }, []);

  async function getReports() {
    const { error, data } = await supabase
      .from("reports")
      .select()
      .eq("verified", true)
      .limit(10);
    // .eq("uid", user?.id);
    const r = data as Report[];

    if (r?.length > 0) {
      Dispatch(setReports(convertToList(r, "id")));
    }

    setRefreshing(false);
  }

  const handleChipPress = (sector: string) => {
    if (selected === sector) {
      setSelected("");
    } else {
      setSelected(sector);
    }
  };

  const openProject = (item: Report) => {
    navigation.navigate("ViewProject", {
      id: item?.id,
    });
  };

  const Item = ({ item }: { item: Report }) => (
    <TouchableOpacity
      style={{ flexDirection: "row" }}
      onPress={() => openProject(item)}
    >
      <Image
        source={
          item?.pictures?.[0]
            ? {
                uri: item?.pictures?.[0],
              }
            : PlaceholderImg
        }
        style={{
          width: 100,
          height: 100,
          borderRadius: 5,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
      <Box
        flex={1}
        paddingLeft={15}
        paddingRight={5}
        paddingVertical={10}
        justifyContent="center"
      >
        <Text style={{ marginBottom: 5, fontSize: 16 }}>
          {item?.projectTitle}
        </Text>
        <Text style={{ fontSize: 12, color: "#bbb" }}>{item?.sector}</Text>
      </Box>
    </TouchableOpacity>
  );

  return (
    <Box
      style={{
        borderTopRightRadius: 40,
        borderTopLeftRadius: 40,
      }}
    >
      <Row
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="transparent"
        // marginBottom={5}
        // paddingHorizontal={15}
      >
        <Text variant="titleLarge" style={{ fontWeight: "bold" }}>
          Recent Projects
        </Text>

        <Button
          mode="text"
          compact
          onPress={() => navigation.navigate("ProjectsTab")}
        >
          See all <ArrowRight2 size={18} />
        </Button>
      </Row>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          marginBottom: 20,
          marginTop: 10,
          paddingVertical: 10,
          height: 50,
        }}
      >
        <ChipItem
          label={"All"}
          selected={selected === ""}
          onPress={() => handleChipPress("")}
        />
        {["completed", "on going", "abandoned"].map((s) => (
          <ChipItem
            key={s}
            label={s}
            selected={selected === s}
            onPress={() => handleChipPress(s)}
          />
        ))}
      </ScrollView>

      <>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingTop: 10,
            paddingBottom: 120,
          }}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={loadData} />
          }
        >
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            rowGap={10}
            columnGap={10}
            backgroundColor="transparent"
          >
            <>
              {allReports
                .filter((r) => (!selected ? true : r.status === selected))
                .map((item, i) => (
                  <Box
                    width={"100%"}
                    backgroundColor="transparent"
                    key={item?.projectTitle + item?.sector + i}
                    borderColor={"#195A93"}
                    borderWidth={0.5}
                    borderRadius={10}
                    padding={0}
                  >
                    <Item item={item} />
                  </Box>
                ))}

              {allReports.filter((r) =>
                !selected ? true : r.status === selected
              )?.length == 0 && <Text>No project yet</Text>}
            </>
          </Box>
        </ScrollView>
      </>
    </Box>
  );
};

type HomeProps = RootTabScreenProps<"HomeTab">;
const Home = ({ navigation }: HomeProps) => {
  const Dispatch = useAppDispatch();
  const appTheme = useTheme();

  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const { messages } = useAppSelector((state) => state.notification);
  const msgs = convertToArray(messages);

  useFocusEffect(() => {
    // Set custom status bar color when this screen is focused
    if (Platform.OS !== "ios") {
      StatusBar.setBackgroundColor("transparent");
      return () => {
        // Clean up when the screen is unfocused (optional)
        StatusBar.setBackgroundColor(appTheme.colors.surface);
      };
    }
  });

  useEffect(() => {
    async function listenNotifications() {
      supabase
        .channel("custom-all-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "notifications" },
          (payload) => {
            // console.log("Change received!", payload);
            if (payload.eventType === "INSERT") {
              const m = payload.new;
              Dispatch(
                addNotification({
                  id: m.id,
                  title: m.title,
                  body: m.body,
                  data: m.data,
                  date: m.date,
                  read: false,
                })
              );
            }
          }
        )
        .subscribe();
    }

    listenNotifications();
  }, []);

  useEffect(() => {
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {});

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        const data = response.notification.request.content.data;

        Notifications.dismissNotificationAsync(
          response.notification.request.identifier
        );
        navigation.navigate("ViewProject", {
          id: data.id,
        });
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ImageBackground source={require("../assets/home-bgd.png")}>
        <View
          style={{
            backgroundColor: "rgba(1, 26, 58, 0.1)",
            height: "100%",
            paddingTop: 200,
          }}
        >
          {/* <StatusBar style="light" backgroundColor="" /> */}
          <Box
            display="flex"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 100,
              right: 0,
              left: 0,
              top: 30,
            }}
          >
            <Box
              flexDirection="row"
              justifyContent="space-between"
              // backgroundColor={"rgba(0,0,0,0.3)"}
              alignItems="center"
              paddingHorizontal={15}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: "red",
                  fontSize: 22,
                }}
              >
                {" "}
              </Text>
              <View>
                <IconButton
                  // icon={"bell-outline"}
                  icon={({ size, color }) => (
                    <Notification size={size} color={color} />
                  )}
                  iconColor={"#f12626"}
                  mode="contained-tonal"
                  containerColor="#fff"
                  onPress={() => navigation.navigate("Notifications")}
                />

                {msgs.length > 0 && (
                  <Badge
                    size={18}
                    style={{
                      position: "absolute",
                      top: 7,
                      right: 10,
                      elevation: 4,
                      zIndex: 1,
                    }}
                  >
                    {msgs.length}
                  </Badge>
                )}
              </View>
            </Box>
          </Box>

          <View
            style={{
              height: "100%",
              backgroundColor: appTheme?.colors?.background,
              borderTopRightRadius: 40,
              borderTopLeftRadius: 40,
              paddingTop: 15,
              paddingHorizontal: 20,
            }}
          >
            <RecentReports navigation={navigation} appTheme={appTheme} />
          </View>
        </View>
      </ImageBackground>
    </GestureHandlerRootView>
  );
};

export default Home;
