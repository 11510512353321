import { NotificationObj } from "./../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationState {
  messages: {
    [id: string]: NotificationObj;
  };
  count: number;
  unreadCount: number;
  isSubscribed: boolean | null;
  pushToken: string | null;
  reminders: {
    [goalId: string]: string[];
  };
}
const initialState: NotificationState = {
  messages: {},
  count: 0,
  unreadCount: 0,
  isSubscribed: null,
  reminders: {},
  pushToken: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<{ [id: string]: NotificationObj }>
    ) => {
      state.messages = action.payload;
      state.unreadCount = Object.values(action.payload).filter(
        (item: NotificationObj) => !item.read
      ).length;
    },
    addNotification: (state, action: PayloadAction<NotificationObj>) => {
      state.messages[action.payload.id] = action.payload;
      // state.count = state.count + 1
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      delete state.messages[action.payload];
      // state.count = state.count - 1
      // state.unreadCount = state.unreadCount - 1
    },
    subscribeToPush: (state, action) => {
      state.isSubscribed = true;
      state.pushToken = action.payload;
    },
  },
});

export const {
  setNotifications,
  addNotification,
  subscribeToPush,
  removeNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
