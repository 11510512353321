import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

// screens
import Login from "./screens/Login";
import CreateReport from "./screens/CreateReport";
import MyReports from "./screens/MyReports";
import Home from "./screens/Home2";
import CreateStream from "./screens/CreateStream";
import {
  Layer,
  Location,
  Messages,
  Microphone,
  User,
} from "iconsax-react-native";

import { RootStackParamList, RootTabParamList } from "./types";
import Settings from "./screens/Settings";
import Support from "./screens/Support";
import Security from "./screens/Security";
import { NavigationTheme } from "react-native-paper/lib/typescript/src/types";
import Projects from "./screens/Projects";
import ViewProjects from "./screens/ViewProjects";
import ViewProject from "./screens/ViewProject";
import ViewState from "./screens/ViewState";
import Notifications from "./screens/Notifications";
import ForgotPassword from "./screens/ForgotPassword";
import SelectCategory from "./screens/SelectCategory";
import AddProject from "./screens/AddProject";
import ViewReports from "./screens/ViewReports";
import { useAppSelector } from "./hooks/redux";
import ChatList from "./screens/ChatList";
import GroupChat from "./screens/GroupChat";
import WelcomeScreen from "./screens/WelcomeScreen";
import SearchProjects from "./screens/SearchProjects";

const Tab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigation() {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <Tab.Navigator
      screenOptions={({}) => ({
        // headerShown: false,
      })}
      initialRouteName="HomeTab"
    >
      <Tab.Screen
        name="HomeTab"
        component={Home}
        options={{
          headerShown: false,
          tabBarLabel: "Home",
          title: "Home",
          tabBarLabelStyle: { marginBottom: 4 },

          tabBarIcon: ({ color, size }) => {
            return <Location color={color} size={20} />;
          },
        }}
      />
      <Tab.Screen
        name="ProjectsTab"
        component={Projects}
        options={{
          tabBarLabel: "Projects",
          title: "Projects",
          headerShown: false,
          tabBarLabelStyle: { marginBottom: 4 },
          // headerStyle: { backgroundColor: "#04203d" },
          headerTintColor: "#fff",
          tabBarIcon: ({ color, size }) => {
            return <Layer color={color} size={20} />;
          },
        }}
      />

      {user?.uid && (
        <>
          <Tab.Screen
            name="LivestreamTab"
            component={CreateStream}
            options={{
              tabBarLabel: "Live stream",
              headerShown: false,
              tabBarLabelStyle: { marginBottom: 4 },
              title: "Audio Room",
              tabBarIcon: ({ color, size }) => {
                return <Microphone color={color} size={20} />;
              },
            }}
          />

          <Tab.Screen
            name="ChatTab"
            options={{
              tabBarLabel: "Chat",
              headerShown: false,
              tabBarLabelStyle: { marginBottom: 4 },
              tabBarIcon: ({ color, size }) => {
                return <Messages color={color} size={20} />;
              },
            }}
            component={ChatList}
          />
        </>
      )}

      <Tab.Screen
        name="SettingsTab"
        options={{
          title: "Account",
          tabBarLabel: "Account",
          tabBarLabelStyle: { marginBottom: 4 },
          headerShown: false,
          tabBarIcon: ({ color, size }) => {
            return (
              // <MaterialCommunityIcons name="cog" size={size} color={color} />
              <User color={color} size={20} />
            );
          },
        }}
        component={Settings}
      />
    </Tab.Navigator>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigation() {
  return (
    <Stack.Navigator initialRouteName="Welcome">
      <>
        <Stack.Screen
          name="Welcome"
          component={WelcomeScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Root"
          component={BottomTabNavigation}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MyReports"
          component={MyReports}
          options={{
            title: "My Reports",
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          }}
        />
        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            // statusBarColor: "#063360"
          }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="Support"
          component={Support}
          options={{
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          }}
        />

        <Stack.Screen
          name="Security"
          component={Security}
          options={{
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          }}
        />
        <Stack.Screen
          name="Notifications"
          component={Notifications}
          options={{
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          }}
        />
        <Stack.Screen
          name="SelectCategory"
          component={SelectCategory}
          options={({ route }) => ({
            title: "Sectors",
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="ViewReports"
          component={ViewReports}
          options={({ route }) => ({
            headerShown: false,
            title: `View Reports`,
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="ViewProjects"
          component={ViewProjects}
          options={({ route }) => ({
            headerShown: false,
            title: `Projects in ${route?.params?.state} State`,
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        {/* <Stack.Group screenOptions={{ presentation: "modal" }}> */}
        <Stack.Screen
          name="SearchProjects"
          component={SearchProjects}
          options={({ route }) => ({
            headerShown: false,
            title: `Search projects`,
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="ViewProject"
          component={ViewProject}
          options={{
            title: "View Project",
            headerStyle: { backgroundColor: "transparent" },
            headerShown: false,
            headerRight: () => (
              <MaterialCommunityIcons name="dots-vertical" size={20} />
            ),
          }}
        />
        <Stack.Screen
          name="ViewState"
          component={ViewState}
          // options={({ route }) => ({
          //   title: route,
          //   headerStyle: { backgroundColor: "transparent" },
          //   headerShown: false,
          //   headerRight: () => (
          //     <MaterialCommunityIcons name="dots-vertical" size={20} />
          //   ),
          // })}
          options={({ route }) => ({
            title:
              route?.params?.state + " State"
                ? "Update Project"
                : "Report a project",
            headerShown: false,
            headerStyle: { backgroundColor: "#04203d" },
            headerRight: () => (
              <MaterialCommunityIcons name="dots-vertical" size={20} />
            ),
            // headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="AddProject"
          component={AddProject}
          options={({ route }) => ({
            title: route?.params?.report?.projectTitle
              ? "Update Project"
              : "Report a project",
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="CreateReport"
          component={CreateReport}
          options={({ route }) => ({
            title: route?.params?.sectorId ? "Submit Report" : "Create Report",
            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        <Stack.Screen
          name="GroupChat"
          component={GroupChat}
          options={({ route }) => ({
            title: route?.params?.state + " Group",
            headerShown: false,

            headerStyle: { backgroundColor: "#04203d" },
            headerTintColor: "#fff",
          })}
        />
        {/* </Stack.Group> */}
      </>
    </Stack.Navigator>
  );
}

type NavigationProps = {
  theme: NavigationTheme;
};
export default function Navigation({ theme }: NavigationProps) {
  return (
    <NavigationContainer theme={theme}>
      <RootNavigation />
    </NavigationContainer>
  );
}
