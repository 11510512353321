import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { View } from "../components/Themed";

import React, { useEffect, useState, useRef } from "react";
import { Report, RootStackParamList } from "../types";

import Header from "../components/Header";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Text,
  useTheme,
  Card,
  ActivityIndicator,
  TextInput,
} from "react-native-paper";

import { activeStates, SECTORS_DETAILS } from "../Constants";
import { supabase } from "../lib/superbase";
import { truncateText } from "../utils/helpers";
import Box from "../components/Box";
import Input from "../components/Input";

type SearchProjectsProps = NativeStackScreenProps<
  RootStackParamList,
  "SearchProjects"
>;
const SearchProjects = ({ navigation, route }: SearchProjectsProps) => {
  const allReports = route.params.data;
  const [search, setSearch] = useState("");

  const ProjectItem = ({ item }: { item: Report }) => {
    return (
      <Pressable
        style={{ width: "48%", marginBottom: 20 }}
        onPress={() => openProject(item)}
      >
        <>
          <Image
            source={
              item?.pictures?.[0]
                ? { uri: item?.pictures?.[0] }
                : require("../assets/logo.jpeg")
            }
            style={{
              width: "100%",
              height: 200,
              marginBottom: 5,
              borderRadius: 5,
            }}
          />
          <Text>{item?.projectTitle} State</Text>
        </>
      </Pressable>
    );
  };

  const openProject = (item: Report) => {
    navigation.navigate("ViewProject", {
      id: item?.id!,
    });
  };

  const ParentComponent = ({ search }: { search: string }) => {
    const projects = allReports?.filter((p) =>
      p.projectTitle?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
    );

    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View
          paddingHorizontal={15}
          display={"flex"}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          columnGap={10}
        >
          {projects?.map((item, i) => (
            <ProjectItem item={item} key={item?.projectTitle + i} />
          ))}

          {projects?.length === 0 ? <Text>No projects found</Text> : <></>}
        </View>
      </ScrollView>
    );
  };

  return (
    <View flex={1}>
      <Header
        title={""}
        justify="flex-start"
        onPressBack={() => navigation.goBack()}
        backgroundColor="transparent"
        right={
          <Input
            style={{ width: 250, height: 40, borderRadius: 50 }}
            variant="outlined"
            onChangeText={setSearch}
          />
        }
      />

      <ParentComponent search={search} />
    </View>
  );
};

export default SearchProjects;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    padding: 15,
  },
});
