import { QuestionsAndAnswers, Report } from "./../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ReportState {
  reports: {
    [id: string]: Report;
  };
  questions: {
    [id: string]: QuestionsAndAnswers;
  };
}

const initialState: ReportState = {
  reports: {},
  questions: {},
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<{ [id: string]: Report }>) => {
      state.reports = { ...action.payload };
    },

    addReport: (state, action: PayloadAction<Report>) => {
      state.reports[action.payload.id] = action.payload;
    },

    deleteReport: (state, action: PayloadAction<Report>) => {
      delete state.reports[action.payload.id];
    },
    addQuestion: (state, action: PayloadAction<QuestionsAndAnswers>) => {
      state.questions[action.payload.sector] = action.payload;
    },
    setQuestion: (
      state,
      action: PayloadAction<{ [id: string]: QuestionsAndAnswers }>
    ) => {
      state.questions = action.payload;
    },
  },
});

export const { deleteReport, addReport, setReports, addQuestion, setQuestion } =
  reportSlice.actions;

export default reportSlice.reducer;
