import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { View } from "../components/Themed";

import React, { useEffect, useState } from "react";
import { Report, RootStackParamList } from "../types";

import Header from "../components/Header";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, ActivityIndicator, Portal, Snackbar } from "react-native-paper";

import { activeStates, SECTORS_DETAILS } from "../Constants";
import { supabase } from "../lib/superbase";
import Box from "../components/Box";

type ViewProjectsProps = NativeStackScreenProps<
  RootStackParamList,
  "ViewProjects"
>;
const ViewProjects = ({ navigation, route }: ViewProjectsProps) => {
  const state = route.params.state;
  const lga = route.params.lga;
  const title = `Projects in ${lga && lga != "" ? lga : state} State`;

  const [allReports, setAllReports] = useState<Report[] | null>(null);
  const [visible, setVisible] = useState<string | null>(null);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getReports = async () => {
      try {
        let q = supabase
          .from("reports")

          .select()
          .eq("state", state);

        if (lga != "") {
          q = q.eq("lga", lga);
        }

        const { data, error } = await q.eq("verified", true);
        // console.log({ data, error, lga });

        if (data && data?.length > 0) {
          const d = data.map((a) => {
            return { ...a, created_at: a.created_at || undefined };
          });

          setAllReports(d);
          // Dispatch(setReports(convertToList(d, "id")));
        } else if (data && data.length == 0) {
          setAllReports([]);
        }

        if (error) {
          setVisible("An error occurred fetching data");
        }
      } catch (error) {
        console.log(error);
      }

      // setLoading(false);
    };

    if (state && activeStates.includes(state)) {
      getReports();
    }
  }, [state, lga]);

  const ChipItem = React.memo(
    ({
      label,
      selected,
      onPress,
    }: {
      label: string;
      selected: boolean;
      onPress: () => void;
    }) => {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={{
            paddingHorizontal: 10,
            paddingVertical: 6,
            marginRight: 10,
            borderRadius: 20,
            backgroundColor: selected ? "#ffffff" : "rgba(255, 255, 255,.1)",
          }}
        >
          <Text style={{ color: selected ? "#000" : "white" }}>{label}</Text>
        </TouchableOpacity>
      );
    }
  );

  const ProjectItem = ({ item }: { item: Report }) => {
    return (
      <Pressable
        style={{ width: "48%", marginBottom: 20 }}
        onPress={() => openProject(item)}
      >
        <>
          <Image
            source={
              item?.pictures?.[0]
                ? { uri: item?.pictures?.[0] }
                : require("../assets/logo.jpeg")
            }
            style={{
              width: "100%",
              height: 200,
              marginBottom: 5,
              borderRadius: 5,
            }}
          />
          <Text>{item?.projectTitle} State</Text>
        </>
      </Pressable>
    );
  };

  const openProject = (item: Report) => {
    navigation.navigate("ViewProject", {
      id: item?.id!,
    });
  };

  const ParentComponent = () => {
    const [selectedSector, setSelectedSector] = useState("");
    const projects = allReports?.filter((p) =>
      selectedSector ? p.sector === selectedSector : true
    );

    const handleChipPress = (sector: string) => {
      // setSelectedSector(sector);
      if (selectedSector === sector) {
        setSelectedSector(""); // Unselect the item
      } else {
        setSelectedSector(sector); // Select the item
      }
    };

    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ScrollView horizontal contentContainerStyle={{ marginBottom: 20 }}>
          <ChipItem
            label={"All"}
            selected={selectedSector === ""}
            onPress={() => handleChipPress("")}
          />
          {SECTORS_DETAILS.map((s) => (
            <ChipItem
              key={s.id}
              label={s.name}
              selected={selectedSector === s.name}
              onPress={() => handleChipPress(s.name)}
            />
          ))}
        </ScrollView>

        <View
          paddingHorizontal={15}
          display={"flex"}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          columnGap={10}
        >
          {projects?.map((item, i) => (
            <ProjectItem item={item} key={item?.projectTitle + i} />
          ))}

          {projects == null && (
            <Box
              justifyContent="center"
              alignItems="center"
              height={150}
              width="100%"
              display="flex"
            >
              <ActivityIndicator color="#fff" />
            </Box>
          )}

          {projects?.length === 0 ? (
            <Text>No projects yet for the selected sector</Text>
          ) : (
            <></>
          )}
        </View>

        <Portal>
          <Snackbar visible={!!visible} onDismiss={() => setVisible(null)}>
            {visible}
          </Snackbar>
        </Portal>
      </ScrollView>
    );
  };

  return (
    <View flex={1}>
      <Header
        title={title}
        onPressBack={() => navigation.goBack()}
        backgroundColor="transparent"
        showSearch
        onPressSearch={() =>
          navigation.navigate("SearchProjects", { data: allReports! })
        }
      />

      <ParentComponent />
    </View>
  );
};

export default ViewProjects;

const styles = StyleSheet.create({
  scrollView: {
    // backgroundColor: "rgba(40,45,60,1)",
    padding: 15,
  },
});
