import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
} from "react-native";
import React from "react";
import useAppTheme from "../hooks/theme";

interface Props extends TextInputProps {
  variant?: "outlined" | "flat";
  style?: StyleProp<TextStyle> | undefined;
  multiline?: boolean;
  numberOfLines?: number | undefined;
}
const Input = ({
  variant,
  style,
  multiline,
  numberOfLines,
  ...rest
}: Props) => {
  const ThemeColors = useAppTheme().colors;

  const borderColor = "#777"; //ThemeColors?.inputBorder;
  const textColor = ThemeColors?.text;
  const placeholderColor = ThemeColors?.inputBorder;
  const textareaStyles = multiline ? styles.textarea : {};

  return (
    <TextInput
      style={[
        { borderColor, color: textColor },
        styles[variant || "flat"],
        textareaStyles,
        style,
      ]}
      numberOfLines={multiline ? numberOfLines || 4 : undefined}
      placeholderTextColor={placeholderColor}
      multiline={multiline}
      {...rest}
    />
  );
};

export default Input;

const styles = StyleSheet.create({
  outlined: {
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    // color: "#000",
  },
  flat: {
    borderWidth: 0,
    padding: 8,
  },
  textarea: {
    textAlignVertical: "top",
  },
});
