import { AllThemes } from "../Constants";

import Box from "./Box";
import Row from "./Row";
import Column from "./Column";
// import { Text } from "./Themed";
import { Text } from "react-native-paper";

import {
  StyleProp,
  TextStyle,
  TouchableHighlight,
  TouchableOpacity,
  useWindowDimensions,
  ViewStyle,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useAppSelector } from "../hooks/redux";
import useAppTheme from "../hooks/theme";
import { ColorValue } from "react-native";
import { ArrowRight2, ArrowRight3 } from "iconsax-react-native";

type BoxProps = React.ComponentProps<typeof Box>;
export interface ListItemParams {
  title: string;
  pl?: number;
  description?: string | React.ReactElement | undefined;
  description2?: React.ReactElement | undefined;
  icon?: string;
  iconSize?: number;
  iconColor?: string;
  callback?: () => void;
  left?: React.ReactElement | undefined;
  right?: React.ReactElement | undefined;
  rightContent?: React.ReactElement | undefined;
  disabled?: boolean;
  showRightIcon?: boolean;
  showUnderline?: boolean;
  py?: number;
  mb?: number;
  px?: number;
  capitalizeTitle?: boolean;
  boxStyles?: StyleProp<ViewStyle>;
  titleStyles?: StyleProp<TextStyle>;
  descriptionStyles?: StyleProp<TextStyle>;
  bgColor?: ColorValue | undefined;
  onLongPress?: () => void;
}
export const ListItem = ({
  title,
  description,
  description2,
  icon,
  iconColor,
  iconSize = 25,
  callback,
  left,
  right,
  pl = 0,
  px = 0,
  mb = 0,
  boxStyles = {},
  disabled = false,
  showRightIcon = true,
  showUnderline = true,
  py = 10,
  capitalizeTitle = false,
  titleStyles = {},
  descriptionStyles = {},
  bgColor = "",
  rightContent,
  onLongPress,
}: ListItemParams) => {
  const { width } = useWindowDimensions();

  const ThemeColors = useAppTheme().colors;

  return (
    <TouchableOpacity
      onPress={!disabled ? callback : () => {}}
      // underlayColor={ThemeColors.background}
      onLongPress={onLongPress}
    >
      <Box
        borderBottomWidth={showUnderline ? 1 : 0}
        backgroundColor={bgColor}
        borderColor={ThemeColors.border}
        paddingHorizontal={px}
        paddingLeft={pl}
        paddingVertical={py}
        marginBottom={mb || 0}
        style={boxStyles}
      >
        <Row
          columnGap={10}
          alignItems="center"
          justifyContent={"space-between"}
          backgroundColor={bgColor}
        >
          <Row columnGap={10} alignItems="center" backgroundColor={bgColor}>
            {left ? left : <></>}

            {!!icon ? (
              <MaterialCommunityIcons
                name={icon}
                size={iconSize}
                color={iconColor || ThemeColors?.primary}
              />
              // <ArrowRight3 size={iconSize}
              // color={iconColor || ThemeColors?.primary}/>
            ) : (
              <></>
            )}
            <Column backgroundColor={bgColor}>
              <Text
                // fontWeight={"m"}

                // _light={{
                //   color: disabled ? colors.coolGray[400] : "coolGray.800",
                // }}
                // _dark={{
                //   color: disabled ? colors.coolGray[600] : "warmGray.50",
                // }}
                style={[
                  {
                    fontWeight: "bold",
                    // color: disabled
                    //   ? ThemeColors?.textMuted
                    //   : ThemeColors?.text,
                    textTransform: capitalizeTitle ? "capitalize" : "none",
                  },
                  titleStyles,
                ]}
              >
                {title}
              </Text>
              {!!description ? (
                <Text
                  // _light={{
                  //   color: disabled
                  //     ? colors.coolGray[400]
                  //     : colors.coolGray[600],
                  // }}
                  // _dark={{
                  //   color: disabled
                  //     ? colors.coolGray[600]
                  //     : colors.coolGray[400],
                  // }}

                  style={[
                    {
                      textTransform: "capitalize",
                      color: ThemeColors?.textMuted,
                      fontSize: 12,
                      maxWidth: width <= 400 ? 300 : 400,
                    },
                    descriptionStyles,
                  ]}
                >
                  {description}
                </Text>
              ) : (
                <></>
              )}

              {description2 ? description2 : <></>}
            </Column>
          </Row>
          {showRightIcon && !right ? (
            <Row alignItems={"center"} backgroundColor={bgColor}>
              {rightContent ? rightContent : <></>}
              {/* <MaterialCommunityIcons
                color={"#ddd"}
                name="chevron-right"
                // size="lg"
              /> */}
              <ArrowRight2 size={12} color={'#ddd'}/>
            </Row>
          ) : (
            <></>
          )}
          {right ? right : <></>}
        </Row>
      </Box>
    </TouchableOpacity>
  );
};
